






















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { BlogBody, isTextContent, isImageContent } from '@/types';

export const DcwBlogContent = defineComponent({
  name: 'DcwBlogContent',
  props: {
    content: {
      type: Array as PropType<BlogBody[]>,
      required: true
    }
  },
  setup() {
    return { isTextContent, isImageContent };
  }
});

export default DcwBlogContent;
