

















































import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import RatingStar from '@/assets/icons/streamline-icons/rating-star.svg';
import { DcwImage } from '@/components/Atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, Testimonial, SlideCount, SliderData } from '@/types';
import ClutchLogo from '@/assets/brands/clutch-full.svg';
import { getSlidePosition } from '@/helpers';
import { useLocales } from '@/composables';
import Backquotes from '@/assets/icons/backquotes.svg';

export const DcwReviewSlide = defineComponent({
  name: 'DcwReviewSlide',
  components: { DcwImage, RatingStar, ClutchLogo, Backquotes },
  props: {
    data: {
      type: Object as PropType<Testimonial>,
      required: true
    },
    sliderData: {
      type: Object as PropType<SliderData>,
      required: true
    },
    slideIndex: {
      type: Number,
      required: true
    }
  },

  setup(props) {
    const { translation } = useLocales();
    const activeIndex = computed(() => props.sliderData.index);
    const position = ref(getSlidePosition(props.slideIndex, props.sliderData));

    const reviewShow = computed(() => position.value === SlideCount.THIRD);
    const hasClutchReview = computed(() => !!props.data.link);

    watch(activeIndex, () => {
      position.value = getSlidePosition(props.slideIndex, props.sliderData);
    });

    const reviewLinkProps = {
      href: hasClutchReview.value ? props.data.link : translation.socialMedia.clutch.url,
      target: '_blank',
      rel: 'noopener'
    };

    const formattedRating = computed<string>(() => {
      const stringRating = props.data.rating.toString();
      if (stringRating.length !== 1) {
        return stringRating;
      }

      return `${stringRating}.0`;
    });

    return {
      position,
      SlideCount,
      reviewShow,
      translation,
      HeadlineTypes,
      reviewLinkProps,
      hasClutchReview,
      formattedRating,
      HeadlineSubtitleSizes
    };
  }
});

export default DcwReviewSlide;
