








import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { LocaleLayoutContent } from '@/types';
import { DcwWideGrid } from '@atoms';
import { DcwLocaleBanner } from '@/components/Pages/Homepage';

export const DcwLocaleLayout = defineComponent({
  name: 'DcwLocaleLayout',
  components: {
    DcwWideGrid,
    DcwLocaleBanner
  },
  props: {
    content: {
      type: Object as PropType<LocaleLayoutContent>,
      required: true
    }
  }
});

export default DcwLocaleLayout;
