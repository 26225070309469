




































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwApplyCardButton } from './components';
import { DcwGridWrapper } from '@atoms';
import { DcwTextPageSidebar } from '@molecules';
import { JobOfferLayoutContent, MainGridWrapperElements, PositionSectionsId, SidebarData } from '@/types';
import { DcwTextPageCTA, DcwSectionWithPeople } from '@/components/Pages/Common';
import {
  DcwPositionBenefits,
  DcwPositionBanner,
  DcwRecruitmentProcess,
  DcwAboutDC,
  DcwPositionDescription,
  DcwApplyNowCard,
  DcwPositionOverview,
  DcwTechnologyStack
} from '@/components/Pages/Position';
import { useLocales } from '@/composables';
import { getHydratedSection } from '@/helpers';

export const DcwJobOfferLayout = defineComponent({
  name: 'DcwJobOfferLayout',
  components: {
    DcwGridWrapper,
    DcwApplyCardButton,
    DcwApplyNowCard,
    DcwTextPageSidebar,
    DcwPositionBanner,
    DcwPositionDescription,
    DcwPositionOverview: getHydratedSection(DcwPositionOverview),
    DcwTechnologyStack: getHydratedSection(DcwTechnologyStack),
    DcwPositionBenefits: getHydratedSection(DcwPositionBenefits),
    DcwRecruitmentProcess: getHydratedSection(DcwRecruitmentProcess),
    DcwSectionWithPeople: getHydratedSection(DcwSectionWithPeople),
    DcwAboutDC: getHydratedSection(DcwAboutDC),
    DcwTextPageCTA: getHydratedSection(DcwTextPageCTA)
  },
  props: {
    content: {
      type: Object as PropType<JobOfferLayoutContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const contentRef = ref(null);

    const sidebarData: SidebarData = {
      sections: [
        { id: PositionSectionsId.DESCRIPTION, title: translation.sidebarNames.description },
        props.content.recruitment ? { id: PositionSectionsId.RECRUITMENT, title: translation.sidebarNames.recruitment } : null,
        { id: PositionSectionsId.TEAM, title: translation.sidebarNames.team },
        { id: PositionSectionsId.BENEFITS, title: translation.sidebarNames.benefits },
        { id: PositionSectionsId.ABOUT, title: translation.sidebarNames.aboutDC }
      ],
      extraSections: [{ id: PositionSectionsId.CTA, title: translation.sidebarNames.cta }]
    };

    return { contentRef, MainGridWrapperElements, PositionSectionsId, sidebarData };
  }
});

export default DcwJobOfferLayout;
