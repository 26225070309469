













import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwButton } from '@atoms';
import { ButtonOptions, ButtonVariants, PagesLinks, MainGridWrapperElements, TextHeroSectionContent } from '@/types';
import { DcwTextPageHero } from '@/components/Pages/Common';
import { useLocales } from '@/composables';
import { isUnfinishedSectionEnabled } from '@/helpers';

export const DcwListingPage = defineComponent({
  name: 'DcwListingPage',
  components: { DcwGridWrapper, DcwButton, DcwTextPageHero },
  props: {
    backButton: {
      type: Object as PropType<ButtonOptions>,
      required: false
    },
    heroContent: {
      type: Object as PropType<TextHeroSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const backButtonProps = {
      isLink: true,
      isLeft: true,
      href: PagesLinks.CAREER,
      variant: ButtonVariants.TERTIARY,
      text: translation.backButtons.career,
      ...props.backButton
    };

    return { backButtonProps, MainGridWrapperElements, isUnfinishedSectionEnabled };
  }
});

export default DcwListingPage;
