
























import { defineComponent, PropType, watch } from '@nuxtjs/composition-api';
import { DcwSliderArrow, DcwFlag } from '@atoms';
import { useSlider } from '@/composables';
import { ContactLocationsSectionContent } from '@/types';

export const DcwLocationSlider = defineComponent({
  name: 'DcwLocationSlider',
  components: { DcwSliderArrow, DcwFlag },
  props: {
    locations: {
      type: Array as PropType<ContactLocationsSectionContent['locations']>,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    }
  },
  emits: ['currentActiveIndex'],
  setup(props, { emit }) {
    const { selectOption, sliderElement, onNextClicked, onPreviousClicked, currentIndex } = useSlider({
      collection: props.locations,
      activeIndex: props.activeIndex
    });

    watch(currentIndex, (index: number) => emit('currentActiveIndex', index));

    return { selectOption, sliderElement, onNextClicked, onPreviousClicked };
  }
});

export default DcwLocationSlider;
