























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline } from '@atoms';
import { DcwVideoPlayer } from '@organisms';
import { HeadlineTypes, PositionAboutSection } from '@/types';

export const DcwAboutDC = defineComponent({
  name: 'DcwAboutDC',
  components: { DcwNewHeadline, DcwVideoPlayer },
  props: {
    content: {
      type: Object as PropType<PositionAboutSection>,
      required: true
    }
  },
  setup(props) {
    const mainHeadlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3
    };

    const subHeadlineProps = {
      ...props.content.footer.headline,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5
    };

    return { mainHeadlineProps, subHeadlineProps };
  }
});

export default DcwAboutDC;
