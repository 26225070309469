


































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwImage, DcwButton, DcwText } from '@atoms';
import { DcwVideoPlayer } from '@organisms';
import {
  QandAText,
  PagesLinks,
  QandISongs,
  QandAImage,
  QandAVideo,
  HeadlineTypes,
  ButtonVariants,
  ImageElementAttributes,
  MainGridWrapperElements
} from '@/types';
import { useLocales } from '@/composables';

export const DcwQAndASection = defineComponent({
  name: 'DcwQAndASection',
  components: { DcwNewHeadline, DcwImage, DcwButton, DcwText, DcwVideoPlayer },
  props: {
    content: {
      type: Object as PropType<QandAText | QandISongs | QandAImage | QandAVideo>,
      required: true
    },
    img: {
      type: Object as PropType<ImageElementAttributes>,
      required: true
    }
  },
  setup() {
    const { translation } = useLocales();

    const ytButtonProps = {
      text: translation.buttons.watchOnYt,
      variant: ButtonVariants.TERTIARY,
      isLink: true,
      target: '_blank',
      rel: 'noopener'
    };

    const jobOfferButtonProps = {
      text: translation.buttons.checkOpenOffers,
      variant: ButtonVariants.TERTIARY,
      isLink: true,
      href: PagesLinks.JOBS
    };

    const spotifyButtonProps = {
      text: translation.buttons.ListenOnSpotify,
      variant: ButtonVariants.TERTIARY,
      isLink: true,
      target: '_blank',
      rel: 'noopener'
    };

    return {
      translation,
      HeadlineTypes,
      ytButtonProps,
      spotifyButtonProps,
      jobOfferButtonProps,
      MainGridWrapperElements,
      joinUs: translation.phrases.joinUs
    };
  }
});

export default DcwQAndASection;
