
























import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { DcwProjectSlider } from './components/DcwProjectSlider.vue';
import { DcwNewHeadline, DcwGridWrapper, DcwSwiperPagination } from '@atoms';
import { DcwSwiperButtons } from '@molecules';
import { useSwiper } from '@/composables';
import { HeadlineContent, Project, HeadlineTypes } from '@/types';
import { getHEXOpacityColor } from '@/helpers';

export const DcwOtherProjects = defineComponent({
  name: 'DcwOtherProjects',
  components: { DcwNewHeadline, DcwGridWrapper, DcwProjectSlider, DcwSwiperButtons, DcwSwiperPagination },
  props: {
    slides: {
      type: Array as PropType<Project[]>,
      required: true
    },
    headline: {
      type: Object as PropType<HeadlineContent>,
      required: true
    }
  },
  setup(props) {
    const { nextSlideHandler, prevSlideHandler, swiperInfo, onSlideChange } = useSwiper({ length: props.slides.length });

    const currentColor = computed(() => getHEXOpacityColor(props.slides[swiperInfo.activeIndex]?.color));
    const borderColor = computed(() => getHEXOpacityColor(props.slides[swiperInfo.activeIndex]?.color).thirty);

    return { swiperInfo, borderColor, currentColor, onSlideChange, HeadlineTypes, nextSlideHandler, prevSlideHandler };
  }
});

export default DcwOtherProjects;
