





























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwButton, DcwImage } from '@atoms';
import { DcwPersonData, DcwScheduleModal } from '@molecules';
import { ButtonVariants, HeadlineTypes, CtaCardProps, HeadlineSubtitleSizes, ButtonOptions } from '@/types';
import { useLocales } from '@/composables';

export const DcwCtaCard = defineComponent({
  name: 'DcwCtaCard',
  components: { DcwNewHeadline, DcwButton, DcwPersonData, DcwScheduleModal, DcwImage },
  props: {
    content: {
      type: Object as PropType<CtaCardProps>,
      required: true
    },
    secondButton: {
      type: Object as PropType<ButtonOptions>,
      required: false
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const modalOpen = ref(false);
    const buttonRef = ref<Vue | null>(null);

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const mainButtonProps = {
      ...props.content.button,
      isLink: !!props.content.button.href
    };

    const scheduleButtonProps = {
      text: translation.buttons.scheduleACall,
      variant: ButtonVariants.SECONDARY,
      isLink: false,
      isSmall: true
    };

    const personDataProps = {
      hasLinkedin: true,
      hasSurname: true,
      person: props.content.person.model,
      headlineLevel: HeadlineTypes.H4
    };

    const clickHandler = () => {
      if (!buttonRef.value || !props.content.button.href) {
        return;
      }

      const buttonElement = buttonRef.value.$el as HTMLAnchorElement;
      buttonElement.click();
    };

    return { scheduleButtonProps, mainButtonProps, headlineProps, modalOpen, personDataProps, clickHandler, buttonRef };
  }
});

export default DcwCtaCard;
