




import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwListWithIcons } from './DcwListWithIcons.vue';
import phpIcon from '@/assets/case/technologies/php.svg';
import javaIcon from '@/assets/case/technologies/java.svg';
import kotlinIcon from '@/assets/case/technologies/kotlin.svg';
import laravelIcon from '@/assets/case/technologies/laravel.svg';
import nodejsIcon from '@/assets/case/technologies/nodejs.svg';
import angularIcon from '@/assets/case/technologies/angular.svg';
import graphqlIcon from '@/assets/case/technologies/graphql.svg';
import javascriptIcon from '@/assets/case/technologies/javascript.svg';
import mysqlIcon from '@/assets/case/technologies/mysql.svg';
import kubernetesIcon from '@/assets/case/technologies/kubernetes.svg';
import reactIcon from '@/assets/case/technologies/react.svg';
import reduxIcon from '@/assets/case/technologies/redux.svg';
import symfonyIcon from '@/assets/case/technologies/symfony.svg';
import typescriptIcon from '@/assets/case/technologies/typescript.svg';
import vueIcon from '@/assets/case/technologies/vue.svg';
import ktorIcon from '@/assets/case/technologies/ktor.svg';
import nuxtIcon from '@/assets/case/technologies/nuxt.svg';
import mongodbIcon from '@/assets/case/technologies/mongodb.svg';
import clutchIcon from '@/assets/case/views/clutch.svg';
import behanceIcon from '@/assets/case/views/behance.svg';
import { SVGIcon } from '@/types';

export const DcwCaseList = defineComponent({
  name: 'DcwCaseList',
  components: { DcwListWithIcons },
  props: {
    items: {
      type: [Array, Object] as PropType<string[] | Record<string, string>>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    linkColor: String
  },
  setup(props) {
    const icons: { [key: string]: SVGIcon } = {
      phpIcon,
      javaIcon,
      kotlinIcon,
      laravelIcon,
      nodejsIcon,
      angularIcon,
      clutchIcon,
      behanceIcon,
      graphqlIcon,
      javascriptIcon,
      mysqlIcon,
      kubernetesIcon,
      reactIcon,
      reduxIcon,
      symfonyIcon,
      typescriptIcon,
      vueIcon,
      ktorIcon,
      nuxtIcon,
      mongodbIcon
    };

    const formatedItems = computed(() => {
      if (!props.items) {
        return;
      }

      if (Array.isArray(props.items)) {
        return props.items.map((name: string) => ({
          name,
          icon: icons[name + 'Icon']
        }));
      } else if (props.items instanceof Object) {
        const keys = Object.keys(props.items);

        return keys.map((name: string, idx: number) => ({
          name,
          icon: icons[name + 'Icon'],
          path: Array.isArray(props.items) ? props.items[idx] : props.items[name]
        }));
      }
    });

    return { formatedItems };
  }
});

export default DcwCaseList;
