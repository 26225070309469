



















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwOurManagement, DcwOffices, DcwDcInNumbers, DcwOurStory } from '@/components/Pages/About';
import {
  DcwSectionWithItems,
  DcwSectionWithGallery,
  DcwJoinUsSection,
  DcwBannerWithVideo,
  DcwTestimonial
} from '@/components/Pages/Common';
import { DcwWideGrid } from '@atoms';
import { getHydratedSection } from '@/helpers';
import { AboutLayoutContent } from '@/types';

export const DcwAboutLayout = defineComponent({
  name: 'DcwAboutLayout',
  components: {
    DcwBannerWithVideo,
    DcwWideGrid,
    DcwDcInNumbers: getHydratedSection(DcwDcInNumbers),
    DcwJoinUsSection: getHydratedSection(DcwJoinUsSection),
    DcwOffices: getHydratedSection(DcwOffices),
    DcwOurManagement: getHydratedSection(DcwOurManagement),
    DcwOurStory: getHydratedSection(DcwOurStory),
    DcwSectionWithGallery: getHydratedSection(DcwSectionWithGallery),
    DcwSectionWithItems: getHydratedSection(DcwSectionWithItems),
    DcwTestimonial: getHydratedSection(DcwTestimonial)
  },
  props: {
    content: {
      type: Object as PropType<AboutLayoutContent>,
      required: true
    }
  }
});

export default DcwAboutLayout;
