















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwLabel, DcwImage, DcwButton, DcwNewHeadline } from '@atoms';
import {
  LabelTypes,
  HeadlineTypes,
  ButtonVariants,
  ContentBlockSpacings,
  TimetableCardVariant,
  TimelineSectionContent
} from '@/types';

export const DcwTimelineMobileCard = defineComponent({
  name: 'DcwTimelineMobileCard',
  components: { DcwLabel, DcwImage, DcwButton, DcwNewHeadline },
  props: {
    event: {
      type: Object as PropType<TimelineSectionContent['events'][number]>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      before: props.event.content.before,
      after: props.event.content.after,
      highlight: props.event.content.highlight,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5
    };

    const buttonProps = {
      text: 'Read more',
      variant: ButtonVariants.PRIMARY,
      href: props.event.link,
      target: props.event.content.firstButton && props.event.content.firstButton.hasTargetBlank ? '_blank' : false,
      rel: props.event.content.firstButton && props.event.content.firstButton.hasTargetBlank ? 'noopener' : false,
      isLink: true
    };

    return { ButtonVariants, LabelTypes, ContentBlockSpacings, TimetableCardVariant, headlineProps, buttonProps };
  }
});

export default DcwTimelineMobileCard;
