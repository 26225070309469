

















































































import { computed, defineComponent, reactive, ref, watch, PropType } from '@nuxtjs/composition-api';
import { DcwLink, DcwButton, DcwFormInput, DcwFormButton, DcwNewHeadline, DcwFormCheckbox, DcwFormFileUpload } from '@atoms';
import IconClickUp from '@/assets/brands/clickup.svg';
import { service } from '@/service';
import { useCaptcha, useLocales, useService, useToast } from '@/composables';
import { Params as ApplyParams } from '@/service/apply/apply';
import { convertFileToDTO, gaHandler, toBytes, getReferrer } from '@/helpers';
import {
  MailType,
  TextInput,
  PagesLinks,
  CustomEvents,
  ApplyCardData,
  HeadlineTypes,
  RequestStatus,
  TextInputTypes,
  ButtonVariants,
  CaptchaActions,
  ApplyCardFormData,
  HeadlineSubtitleSizes,
  DataProcessingSectionsId
} from '@/types';

export const DcwApplyNowCard = defineComponent({
  name: 'DcwApplyNowCard',
  components: {
    DcwLink,
    DcwButton,
    IconClickUp,
    DcwFormInput,
    DcwFormButton,
    DcwNewHeadline,
    DcwFormCheckbox,
    DcwFormFileUpload
  },
  props: {
    data: {
      type: Object as PropType<ApplyCardData>,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-modal'],
  setup(props, { emit }) {
    const DELAY = 5000;
    const { successToast, errorToast } = useToast();
    const { translation } = useLocales();
    const { executeCaptcha } = useCaptcha();
    const { update: applyHandler, error: applyError, status: applyStatus } = useService(service.applyService.apply);
    const { update: sendMessage, error: mailError, status: mailStatus } = useService(service.emailService.sendEmail);
    const { update: sendFeedbackMessage } = useService(service.emailService.sendEmail);

    const formDisabled = ref(false);
    const termsAccepted = ref(false);

    const initialFormData: ApplyCardFormData = {
      name: '',
      mail: '',
      captchaToken: '',
      futureRecruitment: false,
      positionId: props.data.id,
      attachment: [],
      mobile: ''
    };

    const formData = reactive<ApplyCardFormData>({ ...initialFormData });

    const nameFieldProps: TextInput = {
      label: translation.inputFields.fullName,
      placeholder: translation.inputFields.fullNamePlaceholder
    };

    const mobileFieldProps: TextInput = {
      label: translation.inputFields.mobile,
      type: TextInputTypes.TEL,
      placeholder: translation.inputFields.mobilePlaceholder
    };

    const mailFieldProps: TextInput = {
      label: translation.inputFields.yourEmail,
      type: TextInputTypes.EMAIL,
      placeholder: translation.inputFields.yourEmailPlaceholder
    };

    const applyButtonPropsLegacy = {
      variant: ButtonVariants.PRIMARY,
      text: translation.buttons.applyVia,
      icon: false,
      isLink: true,
      href: props.data.clickUpLink,
      rel: 'noopener',
      target: '_blank'
    };

    const headlinePropsLegacy = {
      subtitle: 'Apply to us via the ClickUp. You will be asked to fill a short form.',
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      before: translation.buttons.applyNow,
      type: HeadlineTypes.H3,
      isTinted: true
    };

    const successHandler = async () => {
      const captchaToken = await executeCaptcha(CaptchaActions.EMAIL);

      sendFeedbackMessage({
        type: MailType.applyFeedback,
        position: props.data.name,
        name: formData.name,
        mail: formData.mail,
        captchaToken
      });

      termsAccepted.value = false;
      Object.assign(formData, initialFormData);
      successToast({ delay: DELAY, message: translation.toasts.applicationSent });
    };

    const submitHandler = async () => {
      formDisabled.value = true;
      formData.captchaToken = await executeCaptcha(CaptchaActions.APPLY);
      applyHandler(formData as ApplyParams);
    };

    const responseHandler = () => {
      formDisabled.value = false;
      emit('close-modal');
    };

    const responseStatus = computed(() => {
      if (applyStatus.value === RequestStatus.ERROR && mailStatus.value === RequestStatus.ERROR) {
        return RequestStatus.ERROR;
      }

      if (applyStatus.value === RequestStatus.SUCCESS || mailStatus.value === RequestStatus.SUCCESS) {
        return RequestStatus.SUCCESS;
      }

      if (applyStatus.value === RequestStatus.PENDING || mailStatus.value === RequestStatus.PENDING) {
        return RequestStatus.PENDING;
      }

      return RequestStatus.DEFAULT;
    });

    watch(responseStatus, (status) => {
      if (status === RequestStatus.SUCCESS || status === RequestStatus.ERROR) {
        const submittedData = {
          _event: 'CV-sent',
          event: 'gtm.linkClick',
          status: applyError.value || mailError.value ? 'error' : 'success'
        };

        gaHandler(submittedData);
      }

      if (status === RequestStatus.SUCCESS) {
        successHandler();
      }

      if (status === RequestStatus.ERROR) {
        errorToast({ delay: DELAY });
      }
    });

    watch(applyError, async () => {
      const captchaToken = await executeCaptcha(CaptchaActions.EMAIL);
      const sendingData = {
        positionId: formData.positionId,
        name: formData.name,
        mail: formData.mail,
        captchaToken,
        futureRecruitment: formData.futureRecruitment,
        referrer: getReferrer() || '',
        type: MailType.clickupTaskError,
        cv: formData.attachment && formData.attachment[0] ? await convertFileToDTO(formData.attachment[0]) : undefined
      };

      await sendMessage(sendingData);
    });

    return {
      toBytes,
      formData,
      PagesLinks,
      translation,
      CustomEvents,
      formDisabled,
      termsAccepted,
      submitHandler,
      mailFieldProps,
      responseStatus,
      nameFieldProps,
      responseHandler,
      mobileFieldProps,
      headlinePropsLegacy,
      applyButtonPropsLegacy,
      DataProcessingSectionsId
    };
  }
});

export default DcwApplyNowCard;
