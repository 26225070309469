





















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwStoryQuote } from './components/DcwStoryQuote.vue';
import { DcwGridWrapper, DcwNewHeadline, DcwText } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, MainGridWrapperElements, OurStorySectionContent } from '@/types';

export const DcwOurStory = defineComponent({
  name: 'DcwOurStory',
  components: { DcwGridWrapper, DcwNewHeadline, DcwStoryQuote, DcwText },
  props: {
    content: {
      type: Object as PropType<OurStorySectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      subtitle: null,
      type: HeadlineTypes.H2
    };

    const itemHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { headlineProps, itemHeadlineProps, MainGridWrapperElements };
  }
});

export default DcwOurStory;
