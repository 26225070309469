












import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwGridWrapper, DcwImage, DcwText, DcwScrollSnap } from '@atoms';
import { CareerBenefitsSectionContent, HeadlineTypes } from '@/types';

export const DcwBenefitsSection = defineComponent({
  name: 'DcwBenefitsSection',
  components: { DcwGridWrapper, DcwImage, DcwText, DcwScrollSnap, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<CareerBenefitsSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = computed(() => ({
      ...props.content.headline,
      type: HeadlineTypes.H2
    }));

    return { headlineProps };
  }
});

export default DcwBenefitsSection;
