






















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwOurExpertiseTechnology } from './components/DcwOurExpertiseTechnology.vue';
import { DcwGridWrapper, DcwScrollSnap, DcwNewHeadline } from '@atoms';
import { HeadlineTypes, HomePageSectionIds, OurExpertiseSectionContent } from '@/types';

export const DcwOurExpertiseSection = defineComponent({
  name: 'DcwOurExpertiseSection',
  components: { DcwGridWrapper, DcwScrollSnap, DcwOurExpertiseTechnology, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<OurExpertiseSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2
    };

    const technologyChunks = computed(() =>
      new Array(Math.ceil(props.content.technologies.length / 2))
        .fill(undefined)
        .map((_, i) => props.content.technologies.slice(i * 2, (i + 1) * 2))
    );

    return { headlineProps, technologyChunks, HomePageSectionIds };
  }
});

export default DcwOurExpertiseSection;
