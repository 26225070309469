































































import { defineComponent, ref, PropType, watch, computed, reactive, onMounted } from '@nuxtjs/composition-api';
import { DcwMeetOurTeamTab } from './components/DcwMeetOurTeamTab.vue';
import { DcwButton, DcwGridWrapper, DcwImage, DcwTooltip, DcwSwiper, DcwNewHeadline } from '@atoms';
import { useLocales, useStyleSheet, useSwiper, useScrollSlideIntoView, useBreakpoints } from '@/composables';
import { getHEXOpacityColor } from '@/helpers';
import {
  Directions,
  PagesLinks,
  HeadlineTypes,
  ButtonVariants,
  SwiperPropsOptions,
  HomePageSectionIds,
  MeetOurTeamSectionContent
} from '@/types';

import { WAVE_EMOJI } from '@/consts';

type SwiperRef = Vue & { $children: { $swiper: { slideTo: (slideIndex: number, transitionTime: number) => void } }[] };

export const DcwMeetOurTeamsSection = defineComponent({
  name: 'DcwMeetOurTeamsSection',
  components: {
    DcwImage,
    DcwSwiper,
    DcwButton,
    DcwTooltip,
    DcwGridWrapper,
    DcwNewHeadline,
    DcwMeetOurTeamTab
  },
  props: {
    content: {
      type: Object as PropType<MeetOurTeamSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const { currentWidth } = useBreakpoints();
    const rootRef = ref<HTMLDivElement | null>(null);
    const swiperRef = ref<SwiperRef | null>(null);
    const sectionRef = ref<Vue | null>(null);
    const buttonHighlight = reactive({ width: 0, offset: 0 });

    const currentColor = computed(() => getHEXOpacityColor(props.content.items[swiperInfo.activeIndex].color));

    const { nextSlideHandler, prevSlideHandler, swiperInfo, onSlideChange } = useSwiper({ length: props.content.items.length });

    const setButtonHighlight = () => {
      const tab = rootRef.value?.childNodes[swiperInfo.activeIndex] as HTMLElement | null;

      buttonHighlight.width = tab?.offsetWidth || 0;
      buttonHighlight.offset = tab?.offsetLeft || 0;
    };

    const swiperOptions: SwiperPropsOptions<MeetOurTeamSectionContent['items'][number]> = {
      swiperConfig: {
        spaceBetween: 128,
        speed: 300,
        breakpoints: {
          1025: {
            allowTouchMove: false
          }
        }
      },
      slides: props.content.items,
      onSlideChange: (slideProps) => {
        onSlideChange(slideProps);
        setButtonHighlight();
        useScrollSlideIntoView(rootRef, slideProps.activeIndex);
      }
    };

    useStyleSheet([
      {
        selectors: [`#${HomePageSectionIds.MEET_OUR_TEAMS}`],
        properties: [
          { key: '--dcw-color-primary', value: currentColor.value.hundred },
          { key: '--dcw-color-primary-light', value: currentColor.value.thirty },
          { key: '--dcw-color-secondary-light', value: currentColor.value.five }
        ]
      }
    ]);

    watch(
      () => swiperInfo.activeIndex,
      () => {
        if (sectionRef.value) {
          const sectionEl = sectionRef.value.$el as HTMLDivElement;

          sectionEl.style.setProperty('--dcw-color-primary', currentColor.value.hundred);
          sectionEl.style.setProperty('--dcw-color-primary-light', currentColor.value.thirty);
          sectionEl.style.setProperty('--dcw-color-secondary-light', currentColor.value.five);
        }
      }
    );

    watch(currentWidth, () => {
      setButtonHighlight();
      useScrollSlideIntoView(rootRef, swiperInfo.activeIndex);
    });

    onMounted(() => {
      setButtonHighlight();
    });

    const onTabClick = (tabIndex: number) => {
      if (!swiperRef.value) {
        return;
      }
      swiperRef.value.$children[0].$swiper.slideTo(tabIndex, 0);
      useScrollSlideIntoView(rootRef, tabIndex);
      setButtonHighlight();
    };

    const getColor = (index: number) => getHEXOpacityColor(props.content.items[index].color);

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2
    };

    return {
      rootRef,
      getColor,
      swiperRef,
      onTabClick,
      WAVE_EMOJI,
      Directions,
      PagesLinks,
      sectionRef,
      swiperInfo,
      translation,
      headlineProps,
      swiperOptions,
      ButtonVariants,
      buttonHighlight,
      nextSlideHandler,
      prevSlideHandler,
      HomePageSectionIds
    };
  },
  head: {}
});

export default DcwMeetOurTeamsSection;
