
























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage, DcwNewHeadline } from '@atoms';
import { DcwContentBlock } from '@molecules';
import { ContentBlockSpacings, HeadlineSubtitleSizes, MeetOurTeamsCardData } from '@/types';

export const DcwMeetOurTeamTab = defineComponent({
  name: 'DcwMeetOurTeamTab',
  components: { DcwImage, DcwContentBlock, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<MeetOurTeamsCardData>,
      required: true
    }
  },
  setup(props) {
    const layerEntry = (layer: number, i: number) => [`--dcw-team-member-layer-variant-${i}`, layer];
    const offsetEntry = (offset: number, i: number) => [`--dcw-team-member-offset-variant-${i}`, `${offset}px`];

    const headlineProps = {
      before: props.content.cardContent.before,
      after: props.content.cardContent.after,
      subtitle: props.content.cardContent.subtitle,
      highlight: props.content.cardContent.highlight,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const contentBlockProps = {
      content: {
        ...props.content.cardContent,
        subtitle: {
          text: props.content.cardContent.subtitle,
          tinted: true,
          size: HeadlineSubtitleSizes.SMALL
        }
      },
      spacing: ContentBlockSpacings.EXTRA_LARGE
    };

    return { layerEntry, offsetEntry, contentBlockProps, headlineProps };
  }
});

export default DcwMeetOurTeamTab;
