











































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  HeadlineColor,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing,
  HeadlineTypes,
  MainGridWrapperElements,
  RemoteWorkSectionContent,
  SwiperPropsOptions
} from '@/types';
import { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination, DcwImage, DcwSwiper, DcwScrollSnap, DcwWideGrid } from '@atoms';
import { useSwiper } from '@/composables';

export const DcwRemoteWork = defineComponent({
  name: 'DcwRemoteWork',
  components: { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination, DcwImage, DcwSwiper, DcwScrollSnap, DcwWideGrid },
  props: {
    content: {
      type: Object as PropType<RemoteWorkSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { onSlideChange, nextSlideHandler, prevSlideHandler, swiperInfo } = useSwiper({ length: props.content.images.length });

    const swiperOptions: SwiperPropsOptions<RemoteWorkSectionContent['images'][number]> = {
      swiperConfig: {
        slidesPerView: 'auto',
        spaceBetween: 24,
        speed: 300,
        loop: true,
        breakpoints: {
          991: {
            allowTouchMove: false
          }
        }
      },
      onSlideChange,
      slides: props.content.images
    };

    const commonHeadlineProps = {
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const mainHeadlineProps = {
      ...commonHeadlineProps,
      ...props.content.headline,
      type: HeadlineTypes.H2
    };

    const descriptionHeadlineProps = {
      ...commonHeadlineProps,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    return {
      MainGridWrapperElements,
      mainHeadlineProps,
      descriptionHeadlineProps,
      swiperOptions,
      swiperInfo,
      nextSlideHandler,
      prevSlideHandler,
      HeadlineTypes,
      HeadlineColor,
      HeadlineSubtitleSpacing
    };
  }
});

export default DcwRemoteWork;
