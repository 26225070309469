


































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwNewHeadline } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, RecruitmentProcessItem } from '@/types';
import { useLocales, useSlider } from '@/composables';

export const DcwRecruitmentProcess = defineComponent({
  name: 'DcwRecruitmentProcess',
  components: { DcwNewHeadline },
  props: {
    content: {
      type: Array as PropType<RecruitmentProcessItem[]>,
      required: true
    }
  },
  setup(props) {
    const activeTab = ref(0);
    const { translation } = useLocales();
    const { selectOption, sliderElement, currentIndex } = useSlider({
      collection: props.content,
      activeIndex: activeTab.value
    });

    watch(currentIndex, () => (activeTab.value = currentIndex.value));

    const slideClickHandler = (index: number) => {
      activeTab.value = index;
      selectOption(index);
    };

    const itemHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { activeTab, translation, sliderElement, slideClickHandler, itemHeadlineProps };
  }
});

export default DcwRecruitmentProcess;
