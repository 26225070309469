














import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import DcwCaseFeatured from './components/DcwCaseFeatured.vue';
import DcwCaseSlider from './components/DcwCaseSlider.vue';
import { DcwGridWrapper, DcwNewHeadline } from '@atoms';
import { CaseStudiesSectionContent, HeadlineTypes, MainGridWrapperElements } from '@/types';

export const DcwCaseSection = defineComponent({
  name: 'DcwCaseSection',
  components: { DcwGridWrapper, DcwCaseFeatured, DcwCaseSlider, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<CaseStudiesSectionContent>,
      required: true
    }
  },
  setup() {
    const activeSlide = ref(0);

    const slideChangeHandler = (index: number) => (activeSlide.value = index);

    return { activeSlide, MainGridWrapperElements, slideChangeHandler, HeadlineTypes };
  }
});

export default DcwCaseSection;
