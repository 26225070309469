























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwScrollSnap, DcwButton } from '@atoms';
import { DcwOfferItem } from '@molecules';
import {
  HeadlineSubtitleSizes,
  HeadlineTypes,
  JobOfferItemVariant,
  JobOfferWithId,
  ProjectSection,
  PagesLinks,
  ButtonVariants
} from '@/types';

export const DcwProjectPositions = defineComponent({
  name: 'DcwProjectPositions',
  components: { DcwNewHeadline, DcwOfferItem, DcwScrollSnap, DcwButton },
  props: {
    content: {
      type: Object as PropType<ProjectSection>,
      required: true
    },
    offers: {
      type: Array as PropType<JobOfferWithId[]>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      isTinted: true,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    return { headlineProps, JobOfferItemVariant, PagesLinks, ButtonVariants };
  }
});

export default DcwProjectPositions;
