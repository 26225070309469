






















import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwReviewSlide } from './DcwReviewSlide.vue';
import { DcwSliderArrow, DcwSwiper } from '@atoms';
import { Testimonial } from '@/types';
import { useSwiper } from '@/composables';

export const DcwReviewsSlider = defineComponent({
  name: 'DcwReviewsSlider',
  components: { DcwSwiper, DcwSliderArrow, DcwReviewSlide },
  props: {
    slides: {
      type: Array as PropType<Testimonial[]>,
      required: true
    }
  },
  setup(props) {
    const { onSlideChange, nextSlideHandler, prevSlideHandler, swiperInfo } = useSwiper();
    const isAnimated = ref(false);

    const buttonClickHandler = (cb: () => void) => {
      if (isAnimated.value) {
        return;
      }
      cb();
    };

    const transitionStart = () => {
      isAnimated.value = true;
    };

    const transitionEnd = () => {
      isAnimated.value = false;
    };

    const swiperOptions = {
      swiperConfig: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 850,
        on: {
          transitionStart,
          transitionEnd
        },
        breakpoints: {
          1280: {
            slidesPerView: 3,
            allowTouchMove: false
          }
        }
      },
      slides: props.slides,
      onSlideChange
    };
    return { swiperOptions, nextSlideHandler, prevSlideHandler, swiperInfo, buttonClickHandler };
  }
});

export default DcwReviewsSlider;
