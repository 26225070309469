











import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwTechnologyItem } from './components/DcwTechnologyItem.vue';
import { DcwNewHeadline } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, OfferTechnology } from '@/types';
import { useLocales } from '@/composables';

export const DcwTechnologyStack = defineComponent({
  name: 'DcwTechnologyStack',
  components: { DcwNewHeadline, DcwTechnologyItem },
  props: {
    content: {
      type: Array as PropType<OfferTechnology[]>,
      required: true
    }
  },
  setup() {
    const { translation } = useLocales();

    const headlineProps = {
      before: translation.phrases.techStack,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H3,
      subtitle: translation.phrases.neededSkills,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { headlineProps };
  }
});

export default DcwTechnologyStack;
