

























































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { SwiperSlide } from 'vue-awesome-swiper';
import { SwiperOptions } from 'swiper';
import { DcwNewHeadline, DcwGridWrapper, DcwSwiperPagination, DcwSwiper } from '@atoms';
import { DcwVideoPlayer } from '@organisms';
import {
  HeadlineSubtitleSpacing,
  HeadlineSubtitleSizes,
  HeadlineColor,
  HeadlineTypes,
  SwiperRef,
  SwiperPropsOptions,
  Breakpoints,
  VideoPlayerButtonSize,
  VideoListingIntegrationsContent,
  IntegrationVideoContent
} from '@/types';
import { useBreakpoints, useSwiper } from '@/composables';

export const DcwIntegrationVideos = defineComponent({
  name: 'DcwIntegrationVideos',
  components: { DcwNewHeadline, DcwGridWrapper, DcwSwiperPagination, SwiperSlide, DcwSwiper, DcwVideoPlayer },
  props: {
    headline: {
      type: Object as PropType<VideoListingIntegrationsContent['headline']>,
      required: true
    },
    videos: {
      type: Array as PropType<VideoListingIntegrationsContent['videos']>,
      required: true
    }
  },
  setup(props) {
    const mainHeadlineProps = {
      ...props.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const headlineProps = {
      type: HeadlineTypes.H3,
      color: HeadlineColor.LIGHT,
      styledAs: HeadlineTypes.H4,
      mobileStyledAs: HeadlineTypes.H6,
      subtitleSpacing: HeadlineSubtitleSpacing.SMALL,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      mobileSubtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL
    };

    const sliderRef = ref<SwiperRef | null>(null);
    const { currentWidth } = useBreakpoints();
    const { swiperInfo, onSlideChange, nextSlideHandler, prevSlideHandler, setActiveSlide, onProgressChange } = useSwiper({
      length: props.videos.length,
      sliderRef
    });

    const desktopSlides = computed(() => {
      const f: IntegrationVideoContent[][] = [];

      props.videos.forEach((item) => {
        const lastItem = f[f.length - 1];
        if (!lastItem || lastItem.length > 2 || item.featured) {
          f.push([item]);
          return;
        }

        lastItem.push(item);
      });

      return f;
    });

    const commonSwiperOptions: SwiperOptions = {
      speed: 500,
      spaceBetween: 16,
      slidesPerView: 1,
      breakpoints: {
        [Breakpoints.SMALL]: {
          slidesPerView: 'auto',
          spaceBetween: 24
        },
        [Breakpoints.MEDIUM]: {
          slidesPerView: 'auto',
          spaceBetween: 24,
          allowTouchMove: false
        }
      },
      on: {
        progress(_, progress) {
          onProgressChange(progress);
        }
      }
    };

    const mobileSwiperOptions: SwiperPropsOptions<IntegrationVideoContent> = {
      swiperConfig: commonSwiperOptions,
      onSlideChange,
      slides: props.videos
    };

    const desktopSwiperOptions: SwiperPropsOptions<IntegrationVideoContent[]> = {
      swiperConfig: commonSwiperOptions,
      onSlideChange,
      slides: desktopSlides.value
    };

    const swiperOptions = computed(() => {
      if (currentWidth.value >= Breakpoints.MEDIUM_DESKTOP) {
        return desktopSwiperOptions;
      }

      return mobileSwiperOptions;
    });

    watch(currentWidth, (newValue, oldValue) => {
      if (
        (newValue >= Breakpoints.MEDIUM_DESKTOP && oldValue < Breakpoints.MEDIUM_DESKTOP) ||
        (newValue <= Breakpoints.MEDIUM_DESKTOP && oldValue > Breakpoints.MEDIUM_DESKTOP)
      ) {
        setActiveSlide(0);
      }
    });

    return {
      mainHeadlineProps,
      headlineProps,
      swiperOptions,
      swiperInfo,
      nextSlideHandler,
      prevSlideHandler,
      setActiveSlide,
      Breakpoints,
      currentWidth,
      desktopSlides,
      VideoPlayerButtonSize
    };
  }
});

export default DcwIntegrationVideos;
