/* eslint-disable max-len */
import {
  TimetableCard,
  TimetableCardImagesLayout,
  TimetableCardVariant,
  HeadlineTypes,
  HeadlineSubtitleSizes,
  PagesLinks,
  ProjectIDs
} from '@/types';

export const events: TimetableCard[] = [
  {
    id: 1,
    content: {
      before: 'Launch of Our Own Organisation App',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Case study on Behance',
        to: 'https://www.behance.net/gallery/105657037/Digital-Colliers-Company-Organisation-App'
      },
      subtitle: {
        text:
          'In order to enhance our productivity, we decided to design our internal organization app, supporting daily projects management.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 338,
        height: 306,
        alt: 'The appearance of our application',
        src: '/events/home/event-1/image-1.png'
      }
    ],
    labels: ['Project', 'Business'],
    date: '2020-01-02',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.behance.net/gallery/105657037/Digital-Colliers-Company-Organisation-App',
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-1/image-mobile.png'
    }
  },
  {
    id: 2,
    content: {
      before: 'Oracle Open-World Event, London',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      firstButton: {
        hasTargetBlank: true,
        text: 'Play video',
        to: 'https://www.youtube.com/watch?v=OyB-71ZYluk&list=PLcFwxJMrxygCR2LVCf37QKCVOTcuWBgnY&ab_channel=Oracle'
      },
      secondButton: {
        hasTargetBlank: true,
        text: 'Read more',
        to: 'https://www.oracle.com/uk/openworld/'
      },
      subtitle: {
        text: 'Digital Colliers delegation engaged in Oracle Open-World Event in London. ',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Image from Oracle Open-World Event',
        src: '/events/home/event-2/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Image from Oracle Open-World Event',
        src: '/events/home/event-2/image-2.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Image from Oracle Open-World Event',
        src: '/events/home/event-2/image-3.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-2/image-mobile.png'
    },
    labels: ['Event', 'Business'],
    date: '2020-02-12',
    variant: TimetableCardVariant.SINGLE_COLUMN,

    link: 'https://www.example.com'
  },
  {
    id: 3,
    content: {
      before: 'Everything is Changing',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'See Instagram Post'
      },
      subtitle: {
        text: 'After two years, it was time for changes. We came up with the new, sharp design of our logo!',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 338,
        height: 90,
        alt: 'Our new logotype',
        src: '/events/home/event-3/image-1.png'
      },
      {
        width: 338,
        height: 90,
        alt: 'Our new logotype',
        src: '/events/home/event-3/image-2.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-3/image-mobile.png'
    },
    imagesLayout: TimetableCardImagesLayout.FIRST_PHOTO_BIGGER,
    labels: ['Company'],
    date: '2021-01-12',
    variant: TimetableCardVariant.SINGLE_COLUMN,
    link: 'https://www.example.com'
  },
  {
    id: 4,
    content: {
      before: 'We Gave a Talk About Big-Data',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Read more',
        to: 'https://www.arena-international.com/aifs/'
      },
      subtitle: {
        text:
          'Another big event for our team. We had a great time speaking up, sharing experiences, and learning about AI and Bigdata.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'We Gave a Talk About Big-Data',
        src: '/events/home/event-4/image-1.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-4/image-mobile.png'
    },
    labels: ['Event', 'Business'],
    date: '2021-02-04',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 6,
    content: {
      before: 'Deployed Internal Web App for Glimat',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Case study on Behance',
        to: 'https://www.behance.net/gallery/105918549/Internal-Web-Application-for-Glimat'
      },
      subtitle: {
        text:
          'We were thrilled to support Glimat by preparing an internal web application to help improve service and management processes.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Overview of Glimat application',
        src: '/events/home/event-5/image-1.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-5/image-mobile.png'
    },
    variant: TimetableCardVariant.TWO_COLUMNS,
    labels: ['Project', 'Business'],
    date: '2021-03-30',
    link: 'https://www.example.com'
  },
  {
    id: 7,
    content: {
      before: 'DC & SolidStudio Now Cooperates!',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Go to Contact',
        to: PagesLinks.CONTACT
      },
      subtitle: {
        text:
          'Solid Studio becomes our partner in crime. With combined forces, we will be building development teams for our partners worldwide.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 338,
        height: 306,
        alt: 'Solid Studio logotype',
        src: '/events/home/event-6/image-1.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-6/image-mobile.png'
    },
    labels: ['Business'],
    variant: TimetableCardVariant.TWO_COLUMNS,
    date: '2021-04-03',
    link: 'https://www.example.com'
  },
  {
    id: 8,
    content: {
      before: 'Hackaton Between The Project Teams!',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'See Instagram Post'
      },
      subtitle: {
        text:
          'It was time to take up rivalry in the digital arena. Our project teams competed against each other in our third internal Hackathon.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Hackaton Between The Project Teams',
        src: '/events/home/event-7/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Hackaton Between The Project Teams',
        src: '/events/home/event-7/image-2.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Hackaton Between The Project Teams',
        src: '/events/home/event-7/image-3.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-7/image-mobile.png'
    },
    labels: ['Event', 'Company'],
    date: '2021-04-14',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 9,
    content: {
      before: 'We Have Started Managing New App',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Project description',
        to: `${PagesLinks.PROJECTS}/${ProjectIDs.LOTTERY_PLATFORM}`
      },
      subtitle: {
        text:
          'Our potential goal for successive years is to create an Innovative Lottery SaaS platform that will be a game changer for the industry.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Lottify overview',
        src: '/events/home/event-8/image-1.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-8/image-mobile.png'
    },
    labels: ['Project', 'Business'],
    date: '2021-05-01',
    hasWeAreHiringTooltip: false,
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 10,
    content: {
      before: 'Tatra Mountians Integration Trip',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Watch video on Youtube',
        to: 'https://www.youtube.com/watch?v=TFCQrERVA7Y&ab_channel=DigitalColliers'
      },
      subtitle: {
        text:
          'A picture can say more than words, and imagine we have a whole video! It was such a great time with great people spent in Aspen, Zakopane. ',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Tatra Integration Trip',
        src: '/events/home/event-9/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Tatra Integration Trip',
        src: '/events/home/event-9/image-2.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Tatra Integration Trip',
        src: '/events/home/event-9/image-3.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-9/image-mobile.png'
    },
    labels: ['Integration', 'People'],
    date: '2021-06-04',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 11,
    content: {
      before: 'Ethereum Conference in Paris',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Read more',
        to: 'https://ethcc.io/'
      },
      subtitle: {
        text:
          'While expanding our area of operation, we pay close attention to the cryptocurrency market. Hence why our presence in Paris.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Ethereum Community Conference in Paris',
        src: '/events/home/event-10/image-1.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-10/image-mobile.png'
    },
    labels: ['Event', 'Business'],
    date: '2021-06-20',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 12,
    content: {
      before: 'Vue.js Lectures With Maciej',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Go to Career',
        to: PagesLinks.CAREER
      },
      subtitle: {
        text:
          'As we have some real experts in our team, we share the knowledge. Maciek, our frontend developer, prepared an intro to Vue.js.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Vue.js Lectures With Maciej',
        src: '/events/home/event-11/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Vue.js Lectures With Maciej',
        src: '/events/home/event-11/image-2.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-11/image-mobile.png'
    },
    labels: ['Academy', 'Company'],
    date: '2021-06-20',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 13,
    content: {
      before: 'We’ve Been to SBC Summit in Barcelona',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Read more',
        to: 'https://sbcevents.com/sbc-summit-barcelona/'
      },
      subtitle: {
        text:
          'That was our opportunity to engage with a global audience of senior decision-makers representing more than 450 companies in the industry.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'We’ve Been to SBC Summit in Barcelona',
        src: '/events/home/event-12/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'We’ve Been to SBC Summit in Barcelona',
        src: '/events/home/event-12/image-2.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'We’ve Been to SBC Summit in Barcelona',
        src: '/events/home/event-12/image-3.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'We’ve Been to SBC Summit in Barcelona',
        src: '/events/home/event-12/image-4.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-12/image-mobile.png'
    },
    labels: ['Event', 'Company'],
    date: '2021-06-20',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 14,
    content: {
      before: 'We Were Featured on TechExpo',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Read more',
        to: 'https://www.iottechexpo.com/global/'
      },
      subtitle: {
        text:
          'It was an amazing time at the TexExpo conference in London. We gave a great speech and met new valuable partners. ',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'We Were Featured on TechExpo Prelections',
        src: '/events/home/event-13/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'We Were Featured on TechExpo Prelections',
        src: '/events/home/event-13/image-2.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-13/image-mobile.png'
    },
    imagesLayout: TimetableCardImagesLayout.COLUMN_ORIENTATION,
    labels: ['Event', 'Company'],
    date: '2021-09-07',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 16,
    content: {
      before: 'Half of Our Company  Went to Bologna ',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Watch video on Youtube'
      },
      subtitle: {
        text:
          'We jumped to the next level and took half of our company on a integration trip to Italy! It was a great three days with our polish project team.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Half of Our Company  Went to Bologna',
        src: '/events/home/event-14/image-1.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-14/image-mobile.png'
    },
    labels: ['Integration', 'People'],
    date: '2021-10-01',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 25,
    content: {
      before: 'Innovative Fintech Cash-Flow App ',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Project description',
        to: `${PagesLinks.PROJECTS}/${ProjectIDs.MONITORING_PLATFORM}`
      },
      subtitle: {
        text:
          'This project aims to develop from scratch an application that will enable SMEs leaders to monitor and forecast their cash flow.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Innovative Fintech Cash-Flow App',
        src: '/events/home/event-15/image-1.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-15/image-mobile.png'
    },
    labels: ['Project', 'Business'],
    date: '2021-11-01',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 30,
    content: {
      before: 'Kotlin Workshops',
      highlight: '#upcoming',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Go to Career',
        to: PagesLinks.CAREER
      },
      subtitle: {
        text:
          'A perfect way to learn more about Kotlin? Of course, workshops with Maciej, our exceptional senior backend developer.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Vue.js Lectures With Maciej',
        src: '/events/home/event-16/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Vue.js Lectures With Maciej',
        src: '/events/home/event-16/image-2.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-16/image-mobile.png'
    },
    labels: ['Academy', 'Company'],
    date: '2021-12-16',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 17,
    content: {
      before: 'Vue.js Global',
      highlight: '#upcoming',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Read more',
        to: 'https://vuejs.amsterdam/'
      },
      subtitle: {
        text:
          'Vue.js is one of the most crucial elements in our technology stack. That’s why we would never miss out the Vue.js Global in Amsterdam.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Kotlin Workshops',
        src: '/events/home/event-17/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Kotlin Workshops',
        src: '/events/home/event-17/image-2.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-17/image-mobile.png'
    },
    labels: ['Event', 'Business'],
    date: '2022-02-10',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 19,
    content: {
      before: 'Integration Trip to Spain',
      highlight: '#upcoming',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Go to Career',
        to: PagesLinks.CAREER
      },
      subtitle: {
        text:
          'This spring, as the destination for our integration trip, we chose Spain. Our whole team is getting ready to conquer Barcelona.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'Integration Trip to Spain',
        src: '/events/home/event-18/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'Integration Trip to Spain',
        src: '/events/home/event-18/image-2.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-18/image-mobile.png'
    },
    imagesLayout: TimetableCardImagesLayout.COLUMN_ORIENTATION,
    labels: ['Integration', 'People'],
    date: '2022-03-01',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  },
  {
    id: 20,
    content: {
      before: 'SXSW in Austin Texas',
      highlight: '#upcoming',
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      secondButton: {
        hasTargetBlank: true,
        text: 'Read more',
        to: 'https://www.sxsw.com/conference/'
      },
      subtitle: {
        text:
          'Known for its large-scale festival atmosphere, SXSW is the place for digital creatives of all stripes and now also for us, Digital Colliers.',
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    },
    images: [
      {
        width: 403,
        height: 90,
        alt: 'SXSW in Austin Texas',
        src: '/events/home/event-19/image-1.png'
      },
      {
        width: 403,
        height: 90,
        alt: 'SXSW in Austin Texas',
        src: '/events/home/event-19/image-2.png'
      }
    ],
    mobileImage: {
      width: 180,
      height: 136,
      alt: '',
      src: '/events/home/event-19/image-mobile.png'
    },
    labels: ['Integration', 'People'],
    date: '2022-03-11',
    variant: TimetableCardVariant.TWO_COLUMNS,
    link: 'https://www.example.com'
  }
];
