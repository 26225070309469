




































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper } from '@atoms';
import { HeroFooterContent } from '@/types';

export default defineComponent({
  name: 'DcwUnderBannerSocialsSection',
  components: { DcwGridWrapper },
  props: {
    content: {
      type: Object as PropType<HeroFooterContent>,
      required: true
    }
  }
});
