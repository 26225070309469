























import { defineComponent, PropType, reactive, ref } from '@nuxtjs/composition-api';
import DcwOneOfUsSlide from './components/OneOfUsSlide/DcwOneOfUsSlide.vue';
import { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination, DcwSwiper } from '@atoms';
import {
  Breakpoints,
  OneOfUsSlide,
  HeadlineTypes,
  SwiperPropsOptions,
  OneOfUsSectionContent,
  HeadlineSubtitleSizes,
  MainGridWrapperElements
} from '@/types';
import { useLocales } from '@/composables';

export const DcwOneOfUs = defineComponent({
  name: 'DcwCareerOneOfUs',
  components: { DcwNewHeadline, DcwSwiper, DcwGridWrapper, DcwOneOfUsSlide, DcwSwiperPagination },
  props: {
    content: {
      type: Object as PropType<OneOfUsSectionContent>,
      required: true
    }
  },
  setup(props) {
    const slideIndex = reactive({ real: 0, active: 0 });
    const swiperRef = ref<Vue | null>(null);
    const swiperExtraSpaceForHD = 200;

    const { translation } = useLocales();
    const { headline, slides } = translation.careerPage.oneOfUs;

    const swiperOptions: SwiperPropsOptions<OneOfUsSlide> = {
      slides: props.content.slides,
      onSlideChange: ({ realIndex }) => (slideIndex.real = slideIndex.active = realIndex), // eslint-disable-line no-return-assign
      swiperConfig: {
        loop: true,
        speed: 1000,
        slidesPerView: 1.1,
        centeredSlides: true,
        breakpoints: {
          320: {
            spaceBetween: -56
          },
          360: {
            slidesPerView: 1.4,
            spaceBetween: -32
          },
          420: {
            slidesPerView: 1.1
          },
          480: {
            slidesPerView: 1.3
          },
          512: {
            slidesPerView: 1.15,
            spaceBetween: -56
          },
          [Breakpoints.MOBILE]: {
            slidesPerView: 1.25,
            spaceBetween: 24
          },
          610: {
            slidesPerView: 1.1,
            spaceBetween: -84
          },
          [Breakpoints.SMALL]: {
            spaceBetween: 32,
            slidesPerView: 1.025
          },
          [Breakpoints.LARGE]: {
            spaceBetween: 32,
            slidesPerView: 1.05
          },
          [Breakpoints.FULL_HD]: {
            spaceBetween: 64,
            slidesPerView: 1.075
          },
          [Breakpoints.FULL_HD + swiperExtraSpaceForHD]: {
            spaceBetween: 48,
            slidesPerView: 1.2
          },
          [Breakpoints.FULL_HD + 2 * swiperExtraSpaceForHD]: {
            spaceBetween: 32,
            slidesPerView: 1.3
          }
        }
      }
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H2,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    return { slides, headline, swiperRef, slideIndex, swiperOptions, HeadlineTypes, headlineProps, MainGridWrapperElements };
  }
});

export default DcwOneOfUs;
