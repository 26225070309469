




































import { defineComponent, onMounted, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwButton, DcwImage, DcwScrollSnap } from '@atoms';
import { useBreakpoints } from '@/composables';
import { scrollToSection } from '@/helpers';
import {
  Breakpoints,
  HeadlineTypes,
  ScrollSnapAlign,
  HeadlineSubtitleSizes,
  MainGridWrapperElements,
  TeamAugmentationExplanationContent
} from '@/types';

export const DcwExplanation = defineComponent({
  name: 'DcwExplanation',
  components: { DcwImage, DcwButton, DcwScrollSnap, DcwGridWrapper, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<TeamAugmentationExplanationContent>,
      required: true
    }
  },
  setup(props) {
    const { currentWidth } = useBreakpoints();
    const dcwExplanationContainer = ref<HTMLDivElement | null>(null);
    const listRef = ref<Vue | null>(null);

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const centerSlider = () => {
      if (listRef.value && currentWidth.value < Breakpoints.SMALL) {
        const listEl = listRef.value.$el;
        const wrapperWidth = listEl.scrollWidth;
        const clientWidth = listEl.clientWidth;

        if (wrapperWidth > clientWidth) {
          listEl.scrollLeft = (wrapperWidth - clientWidth) / 2;
        }
      }
    };

    const buttonProps = {
      ...props.content.button,
      isLink: !!props.content.button.href,
      icon: false
    };

    onMounted(centerSlider);
    watch(currentWidth, centerSlider);

    const clickHandler = (evt: MouseEvent) => {
      if (!props.content.button.href || !props.content.button.href.split('#').pop()) {
        return;
      }

      evt.preventDefault();
      scrollToSection(props.content.button.href.split('#').pop());
    };

    return {
      listRef,
      buttonProps,
      clickHandler,
      headlineProps,
      ScrollSnapAlign,
      MainGridWrapperElements,
      dcwExplanationContainer
    };
  }
});

export default DcwExplanation;
