

















import { defineComponent, PropType, watch } from '@nuxtjs/composition-api';
import { DcwGridWrapper } from '@atoms';
import { DcwPageInfo } from '@molecules';
import { DcwStoryDetails, DcwStoryBanner } from '@/components/Pages/SuccessStory';
import { DcwTextPageCTA, DcwSectionWithPeople } from '@/components/Pages/Common';
import { MainGridWrapperElements, SuccessStoriesLayoutContent, HeadlineTypes, CssVariables, ProjectSelectors } from '@/types';
import { useBreakpoints, useStyleSheet } from '@/composables';
import { getHydratedSection } from '@/helpers';

export const DcwSuccessStoryLayout = defineComponent({
  name: 'DcwSuccessStoryLayout',
  components: {
    DcwGridWrapper,
    DcwStoryBanner,
    DcwPageInfo,
    DcwStoryDetails: getHydratedSection(DcwStoryDetails),
    DcwTextPageCTA: getHydratedSection(DcwTextPageCTA),
    DcwSectionWithPeople: getHydratedSection(DcwSectionWithPeople)
  },
  props: {
    content: {
      type: Object as PropType<SuccessStoriesLayoutContent>,
      required: true
    }
  },
  setup() {
    const { isMobile } = useBreakpoints();
    const { updateStyleSheet } = useStyleSheet();

    const headerDesktopProperties = [
      {
        selectors: [ProjectSelectors.HEADER],
        properties: [
          { key: CssVariables.COLOR_BACKGROUND, value: 'transparent' },
          { key: CssVariables.COLOR_SECONDARY_DARK, value: `var(${CssVariables.COLOR_WHITE})` }
        ]
      },
      {
        selectors: [ProjectSelectors.WIDGET_LIST],
        properties: [{ key: CssVariables.COLOR_SECONDARY_DARK, value: `var(${CssVariables.COLOR_SECONDARY_DARK})` }]
      }
    ];

    const headerMobileProperties = [
      {
        selectors: [ProjectSelectors.HEADER],
        properties: [{ key: CssVariables.COLOR_BACKGROUND, value: `var(${CssVariables.COLOR_SKY_BLUE})` }]
      }
    ];

    watch(
      isMobile,
      (value) => {
        updateStyleSheet(value ? headerMobileProperties : headerDesktopProperties);
      },
      { immediate: true }
    );

    return { MainGridWrapperElements, HeadlineTypes };
  },
  head: {}
});

export default DcwSuccessStoryLayout;
