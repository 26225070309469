











































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwVariantsToggler, DcwSelect, DcwGridWrapper } from '@atoms';
import { useBreakpoints, useFilters, useLocales } from '@/composables';
import { DcwOffersList, DcwProjectsList } from '@organisms';
import { DcwTextPageHero } from '@/components/Pages/Common';
import { getSelectOptionLabel } from '@/helpers';

import {
  TextHeroSectionContent,
  CareerListingContent,
  VariantsTogglerProps,
  ListingContentTypes,
  JobOfferItemVariant,
  JobOfferWithId,
  ProjectWithId,
  CustomEvents
} from '@/types';

import BlocksLayout from '@/assets/icons/layout-blocks.svg';
import ListLayout from '@/assets/icons/layout-list.svg';

export const DcwCareerListing = defineComponent({
  name: 'DcwCareerListing',
  components: { DcwTextPageHero, DcwVariantsToggler, DcwSelect, DcwOffersList, DcwGridWrapper, DcwProjectsList },
  props: {
    hero: {
      type: Object as PropType<CareerListingContent>,
      required: true
    },
    activeOption: {
      type: String as PropType<ListingContentTypes>,
      required: true
    },
    offers: {
      type: Array as PropType<JobOfferWithId[]>,
      required: true
    },
    projects: {
      type: Array as PropType<ProjectWithId[]>,
      required: true
    }
  },
  setup(props) {
    const gridVariant = ref<JobOfferItemVariant>(JobOfferItemVariant.CARD);
    const selectedContent = ref<ListingContentTypes>(props.activeOption);
    const { isMobile } = useBreakpoints();
    const { translation } = useLocales();
    const {
      filters: offerFilters,
      filteredList: offersList,
      changeFiltersHandler: changeOfferFilter,
      resetFilters: resetOffersFilters
    } = useFilters<JobOfferWithId>({
      list: props.offers,
      field: 'categories',
      isOffers: true,
      defaultFilter: translation.phrases.all
    });

    const {
      filters: projectFilters,
      filteredList: projectList,
      changeFiltersHandler: changeProjectFilter,
      resetFilters: resetProjectFilters
    } = useFilters<ProjectWithId>({
      list: props.projects,
      field: 'categories',
      defaultFilter: translation.phrases.all
    });

    const jobsFilterProps = {
      name: ListingContentTypes.JOB_OFFERS,
      label: translation.phrases.filterBy,
      length: Object.keys(offerFilters).length,
      options: offerFilters,
      handler: changeOfferFilter
    };

    const projectsFilterProps = {
      name: ListingContentTypes.PROJECTS,
      label: translation.phrases.filterBy,
      length: Object.keys(projectFilters).length,
      options: projectFilters,
      handler: changeProjectFilter
    };

    const selects = [jobsFilterProps, projectsFilterProps];

    watch(selectedContent, () => {
      resetOffersFilters();
      resetProjectFilters();
    });

    watch(isMobile, () => {
      if (!isMobile) {
        return;
      }

      if (gridVariant.value === JobOfferItemVariant.CARD) {
        return;
      }

      gridVariant.value = JobOfferItemVariant.CARD;
    });

    const heroContent: TextHeroSectionContent = {
      ...props.hero,
      headline: {
        highlight: props.hero.headline.highlight,
        before: `${props.offers.length} ${props.hero.headline.firstItem}, ${props.projects.length} ${props.hero.headline.secondItem}\n` // eslint-disable-line max-len
      }
    };

    const variantsProps: VariantsTogglerProps = {
      name: 'show-variant',
      defaultValue: props.activeOption,
      firstOption: {
        value: ListingContentTypes.JOB_OFFERS,
        label: { value: 'Job Offers' }
      },
      secondOption: {
        value: ListingContentTypes.PROJECTS,
        label: { value: 'Projects' }
      }
    };

    const layoutProps: VariantsTogglerProps = {
      name: 'layout-variant',
      defaultValue: gridVariant.value,
      firstOption: {
        value: JobOfferItemVariant.CARD,
        label: { value: 'Blocks', svg: BlocksLayout, hidden: true }
      },
      secondOption: {
        value: JobOfferItemVariant.LIST_ITEM,
        label: { value: 'List', svg: ListLayout, hidden: true }
      },
      isIndicatorShowed: false
    };

    return {
      getSelectOptionLabel,
      ListingContentTypes,
      selectedContent,
      variantsProps,
      CustomEvents,
      projectList,
      layoutProps,
      gridVariant,
      heroContent,
      offersList,
      selects
    };
  }
});

export default DcwCareerListing;
