































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { EventDot } from '@/types';

export const DcwTimelineDot = defineComponent({
  name: 'DcwTimelineDot',
  props: {
    data: {
      type: Object as PropType<EventDot>,
      required: true
    },
    isHomepageVariant: {
      type: Boolean,
      required: false
    },
    isActive: {
      type: Boolean,
      required: false
    },
    canForceCollapse: {
      type: Boolean,
      required: false
    },
    isLastOne: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const isDotIndexMatchingClosestEventIndex = computed(() => props.data.index === props.data.closestEventIndex);

    const isDotIndexMatchingActiveEventIndex = computed(() => props.data.index === props.data.activeEventIndex);

    const isDotIndexHigherThanActiveEventIndex = computed(() => props.data.index > props.data.activeEventIndex);

    const isDotIndexLowerThanActiveEventIndex = computed(() => props.data.index < props.data.activeEventIndex);

    const isDotIndexLowerThanClosestEventIndex = computed(() => props.data.index < props.data.closestEventIndex);

    return {
      isDotIndexMatchingClosestEventIndex,
      isDotIndexMatchingActiveEventIndex,
      isDotIndexHigherThanActiveEventIndex,
      isDotIndexLowerThanActiveEventIndex,
      isDotIndexLowerThanClosestEventIndex
    };
  }
});

export default DcwTimelineDot;
