










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import DcwReviewsSlider from './components/ReviewsSlider.vue';
import { DcwGridWrapper, DcwButton, DcwNewHeadline } from '@atoms';
import { ButtonVariants, HeadlineSubtitleSizes, HeadlineTypes, ReviewsSectionContent } from '@/types';

export const DcwReviewsSection = defineComponent({
  name: 'DcwReviewsSection',
  components: { DcwGridWrapper, DcwReviewsSlider, DcwButton, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<ReviewsSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const buttonProps = {
      ...props.content.button,
      isLink: !!props.content.button.href,
      variant: ButtonVariants.PRIMARY
    };

    return { headlineProps, buttonProps };
  }
});

export default DcwReviewsSection;
