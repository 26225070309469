




























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwButton, DcwImage } from '@atoms';
import { DcwScheduleModal } from '@molecules';
import {
  HeadlineTypes,
  ButtonVariants,
  HeadlineSubtitleSizes,
  MainGridWrapperElements,
  LocaleBannerSectionContent,
  HeadlineSubtitleSpacing
} from '@/types';

export const DcwLocaleBanner = defineComponent({
  name: 'DcwLocaleBanner',
  components: { DcwGridWrapper, DcwButton, DcwImage, DcwScheduleModal, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<LocaleBannerSectionContent>,
      required: true
    }
  },
  setup(props) {
    const isModalShown = ref(false);

    const mobileButtonProps = {
      text: props.content.buttonText,
      variant: ButtonVariants.SECONDARY,
      isSmall: true,
      icon: false
    };

    const desktopButtonProps = {
      text: props.content.buttonText,
      variant: ButtonVariants.PRIMARY,
      icon: false
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H1,
      sizeSize: HeadlineSubtitleSizes.SMALL,
      mobileStyledAs: HeadlineTypes.H3,
      isTinted: true
    };

    const personHeadlineProps = {
      ...props.content.person.data,
      type: HeadlineTypes.P,
      styledAs: HeadlineTypes.H5,
      subtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    return { MainGridWrapperElements, personHeadlineProps, desktopButtonProps, mobileButtonProps, headlineProps, isModalShown };
  }
});

export default DcwLocaleBanner;
