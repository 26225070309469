import { Ref, ref, onMounted, onBeforeUnmount, watch, getCurrentInstance } from '@nuxtjs/composition-api';
import { TimetableCard } from '@/types';
import { useBreakpoints } from '@/composables';

interface IntersectingCard {
  id: number;
  intersecting: boolean
}
interface UseScrollUtilities {
  isScrollInProgress: Ref<boolean>;
  observer: Ref<IntersectionObserver | undefined>;
  listOfIntersectingCards: Ref<IntersectingCard[]>;
}

export const useScrollUtilities = (
  scrolledElementRef: Ref<HTMLElement | null>,
  observedCards: Ref<TimetableCard[]>
): UseScrollUtilities => {
  const isScrollInProgress = ref(false);

  const storeScrollTimeout: Ref<ReturnType<typeof setTimeout> | null> = ref(null);

  const manageTimeout = () => {
    if (storeScrollTimeout.value !== null) {
      clearTimeout(storeScrollTimeout.value);
      storeScrollTimeout.value = null;
      isScrollInProgress.value = true;
    }
    storeScrollTimeout.value = setTimeout(() => {
      isScrollInProgress.value = false;
    }, 30);
  };

  onMounted(() => {
    scrolledElementRef.value?.addEventListener('scroll', () => {
      manageTimeout();
    });
  });

  onBeforeUnmount(() => {
    scrolledElementRef.value?.removeEventListener('scroll', () => {
      manageTimeout();
    });
  });

  const observer = ref<IntersectionObserver>();

  const listOfIntersectingCards = ref<IntersectingCard[]>([]);

  const onElementObserved = (entries: IntersectionObserverEntry[]): void => {
    entries.forEach(({ target, isIntersecting }) => {
      const index = target.getAttribute('data-index');
      if (index) {
        listOfIntersectingCards.value[parseInt(index)].intersecting = isIntersecting;
      }
    });
  };

  if (process.client) {
    observer.value = new IntersectionObserver(onElementObserved, {
      root: scrolledElementRef.value,
      threshold: 1.0
    });
  }

  onMounted(() => {
    listOfIntersectingCards.value = observedCards.value.map((_, index) => ({ id: index, intersecting: false }));
    if (process.client && scrolledElementRef.value) {
      observer.value = new IntersectionObserver(onElementObserved, {
        root: scrolledElementRef.value,
        threshold: 1.0
      });
    }
  });

  onBeforeUnmount(() => {
    observer.value?.disconnect();
  });

  const instance = getCurrentInstance();

  const { currentWidth } = useBreakpoints();

  watch(listOfIntersectingCards, (intersectingElement) => {
    const getIntersectingElements = intersectingElement.filter((element) => element.intersecting);
    if (currentWidth.value > 1024.98 || !instance) {
      return;
    }
    if (getIntersectingElements.length === 1 || getIntersectingElements.length === 2) {
      instance.emit('mobileChangeActiveCardIndex', getIntersectingElements[0].id);
    }

    if (getIntersectingElements.length === 3) {
      instance.emit('mobileChangeActiveCardIndex', getIntersectingElements[1].id);
    }
  }, {
    deep: true
  });

  return {
    isScrollInProgress,
    observer,
    listOfIntersectingCards
  };
};
