

































import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { HeadlineTypes, IntegrationsSectionContent, VueVimeoPlayer, AnimationStates, HeadlineColor } from '@/types';
import { DcwNewHeadline, DcwVimeoPlayer, DcwImage, DcwPlayButton } from '@atoms';
import { useVimeoPlayer } from '@/composables';

export const DcwIntegrationsSlide = defineComponent({
  name: 'DcwIntegrationsSlide',
  components: { DcwNewHeadline, DcwVimeoPlayer, DcwImage, DcwPlayButton },
  props: {
    data: {
      type: Object as PropType<IntegrationsSectionContent['trips'][number]>,
      required: true
    },
    slideIndex: {
      type: Number,
      required: true
    },
    realActiveIndex: {
      type: Number,
      required: true
    },
    numberOfSlides: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const isPreviousSlide = ref(false);

    const headlineAttrs = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5,
      color: HeadlineColor.LIGHT
    };

    const thumbnail = ref<HTMLDivElement | null>(null);
    const player = ref<VueVimeoPlayer | null>(null);

    const { playPause, isVideoPlaying } = useVimeoPlayer(player);

    const animationState = ref<AnimationStates>(AnimationStates.IDLE);
    const isFooterShown = ref(true);
    const videoWasPlayed = ref(false);
    const videoIsVisible = ref(false);

    const isActive = computed(() => props.slideIndex === props.realActiveIndex);

    const prevOrNextClass = computed(() => {
      const isNext =
        props.realActiveIndex + 1 === props.numberOfSlides
          ? props.slideIndex === 0
          : props.realActiveIndex + 1 === props.slideIndex;

      const isPrev =
        props.realActiveIndex === 0
          ? props.slideIndex === props.numberOfSlides - 1
          : props.realActiveIndex - 1 === props.slideIndex;

      return isNext ? 'dcw-trips-slide--next' : isPrev ? 'dcw-trips-slide--prev' : '';
    });

    const hideFooterAndPlayVideo = () => {
      if (animationState.value === AnimationStates.IN_PROGRESS) {
        return;
      }

      videoIsVisible.value = true;
      animationState.value = AnimationStates.IN_PROGRESS;
      videoWasPlayed.value = true;
      isFooterShown.value = false;
      playPause();

      setTimeout(() => {
        animationState.value = AnimationStates.IDLE;
        if (thumbnail.value !== null) {
          thumbnail.value.style.zIndex = '-1';
        }
      }, 700);
    };

    watch(
      () => isActive.value,
      () => {
        if (isVideoPlaying.value) {
          playPause();
        }
        isFooterShown.value = true;
        videoWasPlayed.value = false;
        videoIsVisible.value = false;
        if (thumbnail.value !== null) {
          thumbnail.value.style.zIndex = '3';
        }
        setTimeout(() => {
          animationState.value = AnimationStates.IDLE;
        }, 700);
      }
    );

    watch(
      () => props.realActiveIndex,
      (_, oldValue) => {
        if (oldValue === props.slideIndex) {
          isPreviousSlide.value = true;
          return;
        }

        isPreviousSlide.value = false;
      }
    );

    return {
      player,
      isActive,
      thumbnail,
      isFooterShown,
      headlineAttrs,
      videoWasPlayed,
      videoIsVisible,
      isVideoPlaying,
      isPreviousSlide,
      prevOrNextClass,
      hideFooterAndPlayVideo
    };
  }
});

export default DcwIntegrationsSlide;
