


































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwIntegrationsSlide } from './components/DcwIntegrationsSlide.vue';
import {
  Breakpoints,
  HeadlineTypes,
  SwiperPropsOptions,
  HeadlineSubtitleSizes,
  MainGridWrapperElements,
  IntegrationsSectionContent
} from '@/types';
import { DcwNewHeadline, DcwGridWrapper, DcwSwiper, DcwSwiperPagination } from '@atoms';
import { useSwiper } from '@/composables';

export const DcwIntegrations = defineComponent({
  name: 'DcwIntegrations',
  components: { DcwNewHeadline, DcwGridWrapper, DcwIntegrationsSlide, DcwSwiper, DcwSwiperPagination },
  props: {
    content: {
      type: Object as PropType<IntegrationsSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { swiperInfo, onSlideChange, nextSlideHandler, prevSlideHandler } = useSwiper({ length: props.content.trips.length });
    const sliderRef = ref<Vue | null>(null);

    const mainHeadlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const noSwipingClass = 'swiper-slide-active';

    const swiperOptions: SwiperPropsOptions<IntegrationsSectionContent['trips'][number]> = {
      swiperConfig: {
        speed: 500,
        loop: true,
        spaceBetween: 24,
        centeredSlides: true,
        loopedSlides: 2,
        slidesPerView: 1.1,
        preloadImages: true,
        breakpoints: {
          [Breakpoints.SMALL]: {
            slidesPerView: 1
          },
          [Breakpoints.MEDIUM]: {
            slidesPerView: 1.5,
            spaceBetween: 130,
            allowTouchMove: false,
            noSwipingClass
          },
          [Breakpoints.MEDIUM_DESKTOP]: {
            slidesPerView: 1.5,
            spaceBetween: 150
          }
        }
      },
      slides: props.content.trips,
      onSlideChange
    };

    return {
      swiperInfo,
      sliderRef,
      swiperOptions,
      noSwipingClass,
      nextSlideHandler,
      prevSlideHandler,
      mainHeadlineProps,
      MainGridWrapperElements
    };
  }
});

export default DcwIntegrations;
