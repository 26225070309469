

























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { HomeLayoutContent } from '@/types';
import { DcwJoinUsSection, DcwSectionWithItems, DcwTestimonial, DcwProjectsSection } from '@/components/Pages/Common';
import { getHydratedSection, isUnfinishedSectionEnabled } from '@/helpers';
import { DcwWideGrid } from '@atoms';
import {
  DcwMap,
  DcwBlogSection,
  DcwCaseSection,
  DcwBannerSection,
  DcwReviewsSection,
  DcwExploreOurStory,
  DcwServicesSection,
  DcwWeWorkForSection,
  DcwOurExpertiseSection,
  DcwMeetOurTeamsSection
} from '@/components/Pages/Homepage';

export const DcwHomeLayout = defineComponent({
  name: 'DcwHomeLayout',
  components: {
    DcwBannerSection,
    DcwWideGrid,
    DcwBlogSection: getHydratedSection(DcwBlogSection),
    DcwExploreOurStory: getHydratedSection(DcwExploreOurStory),
    DcwJoinUsSection: getHydratedSection(DcwJoinUsSection),
    DcwOurExpertiseSection: getHydratedSection(DcwOurExpertiseSection),
    DcwSectionWithItems: getHydratedSection(DcwSectionWithItems),
    DcwTestimonial: getHydratedSection(DcwTestimonial),
    DcwServicesSection: getHydratedSection(DcwServicesSection),
    DcwMap: getHydratedSection(DcwMap),
    DcwProjectsSection: getHydratedSection(DcwProjectsSection),
    DcwCaseSection: getHydratedSection(DcwCaseSection),
    DcwReviewsSection: getHydratedSection(DcwReviewsSection),
    DcwWeWorkForSection: getHydratedSection(DcwWeWorkForSection),
    DcwMeetOurTeamsSection: getHydratedSection(DcwMeetOurTeamsSection)
  },
  props: {
    content: {
      type: Object as PropType<HomeLayoutContent>,
      required: true
    }
  },
  setup() {
    return { isUnfinishedSectionEnabled };
  }
});

export default DcwHomeLayout;
