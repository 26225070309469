






































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwImage, DcwButton } from '@atoms';
import { DcwScheduleModal } from '@molecules';
import { ButtonVariants, CareerPathSectionContent } from '@/types';
import LinkedInIcon from '@/assets/brands/linkedin.svg';

export const DcwCareerPathRecruiter = defineComponent({
  name: 'DcwCareerPathRecruiter',
  components: { DcwImage, DcwButton, DcwScheduleModal, LinkedInIcon },
  props: {
    data: {
      type: Object as PropType<CareerPathSectionContent['hrSpecialist']>,
      required: true
    }
  },
  setup() {
    const isModalShown = ref(false);

    const buttonProps = {
      text: 'Schedule a call',
      variant: ButtonVariants.PRIMARY,
      icon: false
    };

    return { buttonProps, isModalShown };
  }
});

export default DcwCareerPathRecruiter;
