






import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwQAndASection } from './components/DcwQAndASection.vue';
import { ProjectSelectors, StoryBodyContent } from '@/types';

export const DcwStoryDetails = defineComponent({
  name: 'DcwStoryDetails',
  components: { DcwQAndASection },
  props: {
    content: {
      type: Object as PropType<StoryBodyContent>,
      required: true
    }
  },
  setup() {
    return { ProjectSelectors };
  }
});

export default DcwStoryDetails;
