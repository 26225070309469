








































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwImage, DcwGridWrapper } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, MainGridWrapperElements, TeamArgumentationBannerContent } from '@/types';

export const DcwTABanner = defineComponent({
  name: 'DcwTABaner',
  components: { DcwNewHeadline, DcwImage, DcwGridWrapper },
  props: {
    content: {
      type: Object as PropType<TeamArgumentationBannerContent>,
      required: true
    }
  },
  setup(props) {
    const mainHeadlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H1,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const descriptionHeadlineProps = {
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H5,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    return { mainHeadlineProps, MainGridWrapperElements, descriptionHeadlineProps };
  }
});

export default DcwTABanner;
