

















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import DcwManWithCard from './components/DcwManWithCard.vue';
import DcwHeroFooter from './components/DcwHeroFooter.vue';
import { DcwGridWrapper, DcwNewHeadline } from '@atoms';
import { DcwContentBlock } from '@molecules';
import { ContentBlockSpacings, HeadlineSubtitleSizes, HeadlineTypes, HomeHeroSectionContent } from '@/types';

export const DcwBannerSection = defineComponent({
  name: 'DcwBannerSection',
  components: { DcwGridWrapper, DcwContentBlock, DcwNewHeadline, DcwManWithCard, DcwHeroFooter },
  props: {
    content: {
      type: Object as PropType<HomeHeroSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      before: props.content.description.before,
      highlight: props.content.description.highlight,
      after: props.content.description.after,
      subtitle: props.content.description.subtitle,
      mobileStyledAs: HeadlineTypes.H3,
      type: HeadlineTypes.H1,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const contentBlockProps = {
      content: { ...props.content.description },
      spacing: ContentBlockSpacings.EXTRA_LARGE
    };

    return { ContentBlockSpacings, HeadlineTypes, headlineProps, contentBlockProps };
  }
});

export default DcwBannerSection;
