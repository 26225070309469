










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { HeadlineTypes, ButtonVariants, TechnologyWithId, PagesLinks } from '@/types';
import { DcwNewHeadline, DcwTechnology, DcwButton } from '@atoms';
import { desktopNavigateTo, isUnfinishedSectionEnabled } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwOurExpertiseTechnology = defineComponent({
  name: 'DcwOurExpertiseTechnology',
  components: { DcwNewHeadline, DcwTechnology, DcwButton },
  props: {
    technology: {
      type: Object as PropType<TechnologyWithId>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const technologyLink = isUnfinishedSectionEnabled ? `${PagesLinks.TECHNOLOGIES}/${props.technology.id}` : PagesLinks.CONTACT;

    const buttonProps = {
      text: translation.buttons.findOutMore,
      variant: ButtonVariants.TERTIARY,
      isLink: true,
      href: technologyLink
    };

    const headlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      before: props.technology.name
    };

    const clickHandler = () => {
      desktopNavigateTo(technologyLink, false);
    };

    return { clickHandler, headlineProps, buttonProps };
  }
});

export default DcwOurExpertiseTechnology;
