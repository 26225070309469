












































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwText, DcwTooltip, DcwNewHeadline, DcwBadgeList } from '@atoms';
import {
  Directions,
  PagesLinks,
  ProjectIDs,
  HeadlineTypes,
  ProjectWithId,
  ButtonVariants,
  SliderDirection,
  ProjectSectionsId
} from '@/types';
import { useLocales } from '@/composables';
import { getHEXOpacityColor } from '@/helpers';
import { offersService } from '@/service/model';

export const DcwProjectSlide = defineComponent({
  name: 'DcwProjectSlide',
  components: { DcwText, DcwTooltip, DcwButton, DcwBadgeList, DcwNewHeadline },
  props: {
    slide: {
      type: Object as PropType<ProjectWithId>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const { getOffersByProjectId } = offersService;

    const projectOffers = getOffersByProjectId(props.slide.id as ProjectIDs);
    const jobOffersCountText = `${projectOffers.length} ${
      projectOffers.length === 1 ? translation.phrases.oneActiveJobOffer : translation.phrases.manyActiveJobOffers
    }`;

    const buttonProps = {
      variant: ButtonVariants.PRIMARY,
      text: translation.buttons.findOutMore,
      isLink: true,
      href: PagesLinks.PROJECTS + '/' + props.slide.id
    };

    const openProjectPositionButton = {
      text: translation.buttons.openProjectJobs,
      variant: ButtonVariants.TERTIARY
    };

    const borderColor = getHEXOpacityColor(props.slide.color).thirty;
    const backgroundColor = getHEXOpacityColor(props.slide.color).five;

    const technologyBadgeList = {
      title: `${translation.phrases.technologies}:`,
      badges: props.slide.categories,
      colors: { border: borderColor, content: props.slide.color }
    };

    const industryBadgeList = {
      title: `${props.slide.fields.industry.label}:`,
      badges: props.slide.fields.industry.value,
      colors: { border: borderColor, content: props.slide.color }
    };

    const tooltipText = translation.tooltip.percentRemote;

    return {
      Directions,
      PagesLinks,
      borderColor,
      tooltipText,
      buttonProps,
      HeadlineTypes,
      ButtonVariants,
      backgroundColor,
      SliderDirection,
      ProjectSectionsId,
      industryBadgeList,
      jobOffersCountText,
      technologyBadgeList,
      openProjectPositionButton
    };
  }
});

export default DcwProjectSlide;
