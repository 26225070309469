















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage } from '@atoms';
import { ImageElementAttributes, TimetableCardImagesLayout, TimetableCardVariant } from '@/types';

export const DcwTimelineGallery = defineComponent({
  name: 'DcwTimelineGallery',
  components: { DcwImage },
  props: {
    images: {
      type: Array as PropType<ImageElementAttributes[]>,
      required: true
    },
    layout: {
      type: String as PropType<TimetableCardImagesLayout>,
      required: false
    },
    variant: {
      type: String as PropType<TimetableCardVariant>,
      required: true
    }
  }
});

export default DcwTimelineGallery;
