

















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwSocialMedia } from '@atoms';
import { DcwVideoPlayer } from '@organisms';
import {
  HeadlineColor,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing,
  HeadlineTypes,
  VideoListingHeroContent,
  VideoPlayerButtonSize
} from '@/types';
import { useLocales } from '@/composables';

export const DcwVideoListingHero = defineComponent({
  name: 'DcwVideoListingHero',
  components: { DcwGridWrapper, DcwVideoPlayer, DcwNewHeadline, DcwSocialMedia },
  props: {
    headline: {
      type: Object as PropType<VideoListingHeroContent['headline']>,
      required: true
    },
    video: {
      type: Object as PropType<VideoListingHeroContent['video']>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const headlineProps = {
      ...props.headline,
      type: HeadlineTypes.H1,
      isTinted: true,
      mobileStyledAs: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      mobileSubtitleSpacing: HeadlineSubtitleSpacing.EXTRA_LARGE
    };

    const descriptionProps = {
      type: HeadlineTypes.H2,
      mobileStyledAs: HeadlineTypes.H6,
      color: HeadlineColor.LIGHT
    };

    const socialMediaLinks = [translation.socialMedia.youtube, translation.socialMedia.vimeo, translation.socialMedia.instagram];

    return { headlineProps, socialMediaLinks, descriptionProps, VideoPlayerButtonSize };
  }
});

export default DcwVideoListingHero;
