














import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwBlogPost } from './components/DcwBlogPost.vue';
import { DcwButton, DcwGridWrapper, DcwScrollSnap, DcwNewHeadline } from '@atoms';
import { HeadlineTypes, ButtonVariants, BlogSectionContent } from '@/types';

export const DcwBlogSection = defineComponent({
  name: 'DcwBlogSection',
  components: { DcwGridWrapper, DcwBlogPost, DcwButton, DcwScrollSnap, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<BlogSectionContent>,
      required: true
    }
  },
  setup(props) {
    const moreButtonProps = {
      ...props.content.button,
      isLink: !!props.content.button.href,
      variant: ButtonVariants.SECONDARY
    };

    return { moreButtonProps, HeadlineTypes };
  }
});

export default DcwBlogSection;
