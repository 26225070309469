












import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwWideGrid } from '@atoms';
import {
  DcwOthersTechnologies,
  DcwWhyTechnology,
  DcwBanner,
  DcwTechnologyCta,
  DcwExtendYourTeam
} from '@/components/Pages/Technology';
import { useStyleSheet } from '@/composables';
import { CssVariables, ProjectSelectors, TechnologyLayoutContent } from '@/types';
import { getHEXOpacityColor, getHydratedSection } from '@/helpers';

export const DcwTechnologyLayout = defineComponent({
  name: 'TechnologyLayout',
  components: {
    DcwWideGrid,
    DcwBanner,
    DcwOthersTechnologies: getHydratedSection(DcwOthersTechnologies),
    DcwWhyTechnology: getHydratedSection(DcwWhyTechnology),
    DcwTechnologyCta: getHydratedSection(DcwTechnologyCta),
    DcwExtendYourTeam: getHydratedSection(DcwExtendYourTeam)
  },
  props: {
    content: {
      type: Object as PropType<TechnologyLayoutContent>,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const colors = getHEXOpacityColor(props.color);

    useStyleSheet([
      {
        selectors: [ProjectSelectors.HEADER, ProjectSelectors.MAIN],
        properties: [
          { key: CssVariables.COLOR_PRIMARY, value: colors.hundred },
          { key: CssVariables.COLOR_PRIMARY_LIGHT, value: colors.fifteen },
          { key: CssVariables.COLOR_BACKGROUND, value: colors.five },
          { key: '--dcw-color-primary-lighter', value: colors.thirty },
          { key: '--dcw-color-primary-ultra-light', value: colors.twenty }
        ]
      }
    ]);
  },
  head: {}
});

export default DcwTechnologyLayout;
