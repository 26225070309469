


























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline } from '@atoms';
import { DcwTextPageSidebar } from '@molecules';
import { HeadlineTypes, MainGridWrapperElements, PagesLinks, TextPageContent } from '@/types';
import { useLocales, usePage } from '@/composables';
import { DcwTextPageHero } from '@/components/Pages/Common';
import { PageMetaData } from '@/composables/use-meta-description';

export const DcwTextPageLayout = defineComponent({
  name: 'DcwTextPageLayout',
  components: { DcwGridWrapper, DcwTextPageSidebar, DcwNewHeadline, DcwTextPageHero },
  props: {
    content: {
      type: Object as PropType<TextPageContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const contentRef = ref(null);
    const headlineProps = {
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H4
    };

    const dataField: PageMetaData = { title: props.content.title, description: props.content.metaDescription };

    if (props.content.ogCover) {
      dataField.ogCover = props.content.ogCover;
    }

    usePage({
      data: dataField,
      breadcrumbs: [
        { name: translation.pagesNames.home, url: PagesLinks.HOME },
        { name: props.content.title, url: '' }
      ]
    });

    return { MainGridWrapperElements, contentRef, headlineProps };
  },
  head: {}
});

export default DcwTextPageLayout;
