
































































import { defineComponent, reactive, ref } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { DcwFormCheckbox, DcwFormInput, DcwLink, DcwFormButton } from '@atoms';
import {
  ButtonVariants,
  ContactFormData,
  RequestStatus,
  MailType,
  TextInputTypes,
  PagesLinks,
  CustomEvents,
  DataProcessingSectionsId,
  CaptchaActions
} from '@/types';
import { service } from '@/service';
import { useCaptcha, useLocales, useService, useToast } from '@/composables';

export const DcwContactForm = defineComponent({
  name: 'DcwContactForm',
  components: { DcwFormCheckbox, DcwFormInput, DcwLink, DcwFormButton },
  setup() {
    const { translation } = useLocales();
    const formDisabled = ref(false);
    const { executeCaptcha } = useCaptcha();
    const { update: sendContactRequest, status: requestStatus, error } = useService(service.emailService.sendEmail);
    const { successToast, errorToast } = useToast();

    const observerRef = ref<InstanceType<typeof ValidationObserver> | null>(null);
    const terms = ref(false);

    const formData = reactive<ContactFormData>({
      name: '',
      mail: '',
      message: ''
    });

    const resetFormData = () => {
      formDisabled.value = false;

      if (process.env.NODE_ENV === 'development') {
        return;
      }

      formData.name = '';
      formData.mail = '';
      formData.message = '';
      terms.value = false;
      observerRef.value?.reset();
    };

    const handleRequestStatus = () => {
      if (error.value) {
        return;
      }

      resetFormData();
      successToast({ delay: 5000 });
    };

    const submitHandler = async () => {
      if (!terms.value) {
        return;
      }

      formDisabled.value = true;
      const captchaToken = await executeCaptcha(CaptchaActions.EMAIL);

      await sendContactRequest({ ...formData, captchaToken, type: MailType.contact });

      if (requestStatus.value === RequestStatus.ERROR) {
        errorToast();
      }
    };

    return {
      formData,
      ButtonVariants,
      submitHandler,
      terms,
      requestStatus,
      translation,
      observerRef,
      TextInputTypes,
      PagesLinks,
      successToast,
      CustomEvents,
      handleRequestStatus,
      DataProcessingSectionsId,
      formDisabled
    };
  }
});

export default DcwContactForm;
