






























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwSalaryInfo } from './components/DcwSalaryInfo.vue';
import { DcwNewHeadline, DcwText, DcwButton, DcwImage, DcwBadgeList } from '@atoms';
import {
  ButtonVariants,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing,
  HeadlineTypes,
  JobOfferOverviewSectionContent,
  SalaryField
} from '@/types';
import { formatSalary } from '@/helpers';

export const DcwPositionOverview = defineComponent({
  name: 'DcwPositionOverview',
  components: { DcwNewHeadline, DcwText, DcwButton, DcwImage, DcwBadgeList, DcwSalaryInfo },
  props: {
    content: {
      type: Object as PropType<JobOfferOverviewSectionContent>,
      required: true
    },
    salaryInfo: {
      type: Object as PropType<SalaryField>,
      required: true
    }
  },
  setup(props) {
    const mainHeadlineProps = {
      ...props.content.mainHeadline,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      type: HeadlineTypes.H3
    };

    const headlineProps = {
      type: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    const engagementHeadlineProps = {
      ...headlineProps,
      highlight: formatSalary(props.salaryInfo.salary),
      subtitleSpacing: HeadlineSubtitleSpacing.LARGE,
      subtitle: props.content.engagement.vacationsText
    };

    const buttonProps = {
      text: props.content.location.button.text,
      isLink: !!props.content.location.button.to,
      href: props.content.location.button.to,
      variant: ButtonVariants.TERTIARY
    };

    return { buttonProps, headlineProps, mainHeadlineProps, engagementHeadlineProps };
  }
});

export default DcwPositionOverview;
