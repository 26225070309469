



























































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwCaseList } from './DcwCaseList.vue';
import { DcwButton, DcwImage, DcwTooltip, DcwText } from '@atoms';
import { WAVE_EMOJI } from '@/consts';
import { CaseStudiesSlide, ButtonVariants, TooltipColors, Directions, SliderDirection, PagesLinks } from '@/types';
import { useLocales } from '@/composables';

export const DcwCaseSlide = defineComponent({
  name: 'DcwCaseSlide',
  components: { DcwText, DcwImage, DcwButton, DcwTooltip, DcwCaseList },
  props: {
    data: {
      type: Object as PropType<CaseStudiesSlide>,
      required: true
    },
    direction: String,
    isCurrent: Boolean
  },
  setup() {
    const { translation } = useLocales();

    return { WAVE_EMOJI, TooltipColors, ButtonVariants, Directions, SliderDirection, translation, PagesLinks };
  }
});

export default DcwCaseSlide;
