









import { defineComponent, onMounted, PropType, ref } from '@nuxtjs/composition-api';
import { DcwNewHeadline } from '@atoms';
import { useGsap, useLocales } from '@/composables';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes, OfferTechnology } from '@/types';

export const DcwTechnologyItem = defineComponent({
  name: 'DcwTechnologyItem',
  components: { DcwNewHeadline },
  props: {
    technology: {
      type: Object as PropType<OfferTechnology>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const { timeLine: tl } = useGsap();
    const itemRef = ref<HTMLElement | null>(null);
    const indicatorRef = ref<HTMLDivElement | null>(null);

    const headlineProps = {
      isTinted: true,
      type: HeadlineTypes.H4,
      styledAs: HeadlineTypes.H5,
      before: props.technology.label,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      subtitle: translation.technologyLevels[props.technology.level]
    };

    onMounted(() => {
      if (!itemRef.value || !indicatorRef.value) {
        return;
      }
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              tl.to(indicatorRef.value, {
                width: props.technology.level * 20 + '%',
                ease: 'power3.out',
                duration: props.technology.level * 0.5
              });
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 1 }
      );

      observer.observe(itemRef.value);
    });

    return { headlineProps, indicatorRef, itemRef };
  }
});

export default DcwTechnologyItem;
