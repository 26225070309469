



































import { defineComponent, PropType, useAsync } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwButton, DcwNewHeadline, DcwTooltip, DcwImage, DcwSeniorityLevels } from '@atoms';
import {
  PagesLinks,
  HeadlineTypes,
  ButtonVariants,
  SeniorityLevels,
  MainGridWrapperElements,
  PositionBannerSectionContent
} from '@/types';
import { formatSalary } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwPositionBanner = defineComponent({
  name: 'DcwPositionBanner',
  components: {
    DcwImage,
    DcwButton,
    DcwTooltip,
    DcwGridWrapper,
    DcwNewHeadline,
    DcwSeniorityLevels
  },
  props: {
    content: {
      type: Object as PropType<PositionBannerSectionContent>,
      required: true
    }
  },
  setup(props) {
    const salaryInformation = formatSalary(props.content.salary);
    const { translation } = useLocales();

    const imageProps = useAsync(() => props.content.images[~~(Math.random() * props.content.images.length)]);

    const backButtonProps = {
      text: translation.backButtons.positions,
      variant: ButtonVariants.TERTIARY,
      href: PagesLinks.JOBS,
      isLink: true,
      isLeft: true
    };

    const headlineProps = {
      type: HeadlineTypes.H1,
      styledAs: HeadlineTypes.H2
    };

    return {
      imageProps,
      translation,
      headlineProps,
      SeniorityLevels,
      backButtonProps,
      salaryInformation,
      MainGridWrapperElements
    };
  }
});

export default DcwPositionBanner;
