var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization"},[_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__mobile-channels"},_vm._l((_vm.content.channelGroups.map(function (ref) {
      var channels = ref.channels;

      return channels;
}).flat()),function(channel,index){return _c('div',{key:index,staticClass:"dcw-hybrid-work-structure-tab-organization__channel",class:{
        'dcw-hybrid-work-structure-tab-organization__channel--active': channel.active,
        'dcw-hybrid-work-structure-tab-organization__channel--notification': channel.notification
      }},[_c('span',[_vm._v(_vm._s(channel.label))])])}),0),_vm._v(" "),_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__channels"},_vm._l((_vm.content.channelGroups),function(group,groupIndex){return _c('DcwAccordion',{key:groupIndex,attrs:{"label":((group.label.toLowerCase()) + " channel"),"is-open":true},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('span',[_vm._v(_vm._s(group.label))])]},proxy:true}],null,true)},[_vm._v(" "),_vm._l((group.channels),function(channel,channelIndex){return _c('div',{key:channelIndex,staticClass:"dcw-hybrid-work-structure-tab-organization__channel",class:{
          'dcw-hybrid-work-structure-tab-organization__channel--active': channel.active,
          'dcw-hybrid-work-structure-tab-organization__channel--notification': channel.notification
        }},[_c('span',[_vm._v(_vm._s(channel.label))])])})],2)}),1),_vm._v(" "),_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__chat"},[_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__messages"},_vm._l((_vm.content.messages),function(message,index){return _c('div',{key:index,staticClass:"dcw-hybrid-work-structure-tab-organization__message-wrapper"},[_c('DcwImage',_vm._b({staticClass:"dcw-hybrid-work-structure-tab-organization__author-image"},'DcwImage',message.image,false)),_vm._v(" "),_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__author-name"},[_c('span',[_vm._v(_vm._s(message.name))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(message.position))])]),_vm._v(" "),_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__content"},[_c('DcwText',{attrs:{"text":message.content}})],1)],1)}),0),_vm._v(" "),_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__input"},[_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__input-text"},[_c('span',[_vm._v(_vm._s(_vm.content.input.string))]),_vm._v(" "),_vm._l((_vm.content.input.emoji),function(emoji,index){return [_c('DcwImage',_vm._b({key:("emoji-" + index)},'DcwImage',emoji,false))]})],2),_vm._v(" "),_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__input-controls"},[_c('div',{staticClass:"dcw-hybrid-work-structure-tab-organization__input-styles"},[_c('IconStyleBold'),_vm._v(" "),_c('IconStyleItalic'),_vm._v(" "),_c('IconStyleStrikethrough')],1),_vm._v(" "),_c('IconSend')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }