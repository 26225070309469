







































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SeniorityLevels, SenioritySalary } from '@/types';
import { getSeniorityLevels, formatSalaryNumber } from '@/helpers';

export const DcwSalaryInfo = defineComponent({
  name: 'DcwSalaryInfo',
  props: {
    content: {
      type: Object as PropType<SenioritySalary>,
      required: true
    },
    level: {
      type: String as PropType<SeniorityLevels>,
      required: true
    }
  },
  setup(props) {
    const filteredLevels = getSeniorityLevels(props.level, true);

    return { filteredLevels, formatSalaryNumber, SeniorityLevels };
  }
});

export default DcwSalaryInfo;
