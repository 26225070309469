



























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwHWSNavigation, DcwHWSTabCommunication, DcwHWSTabOrganization, DcwHWSTabCoworking, DcwAside } from './components';
import { DcwGridWrapper, DcwNewHeadline, DcwSwiper } from '@atoms';
import {
  HWSTabs,
  HeadlineTypes,
  HWSSectionContent,
  HWSAsideContentType,
  HeadlineSubtitleSizes,
  MainGridWrapperElements
} from '@/types';

export const DcwHybridWorkStructure = defineComponent({
  name: 'DcwHybridWorkStructure',
  components: {
    DcwAside,
    DcwSwiper,
    DcwGridWrapper,
    DcwNewHeadline,
    DcwHWSNavigation,
    DcwHWSTabCoworking,
    DcwHWSTabOrganization,
    DcwHWSTabCommunication
  },
  props: {
    content: {
      type: Object as PropType<HWSSectionContent>,
      required: true
    }
  },
  setup(props) {
    const activeTab = ref(0);

    const swiperOptions = {
      slides: Object.entries(props.content.tabs).map(([key, value]) => ({ key, content: value.content })),
      swiperConfig: {
        speed: 500,
        spaceBetween: 64,
        allowTouchMove: false
      }
    };

    const tabsLabels = Object.values(props.content.tabs).map(({ label }) => label);
    const tabsArray = Object.values(props.content.tabs);

    const asideHeadlineProps = {
      type: HeadlineTypes.H3,
      subtitle: {
        size: HeadlineSubtitleSizes.SMALL,
        tinted: true
      }
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    return {
      HWSTabs,
      tabsArray,
      activeTab,
      tabsLabels,
      headlineProps,
      swiperOptions,
      asideHeadlineProps,
      HWSAsideContentType,
      MainGridWrapperElements
    };
  }
});

export default DcwHybridWorkStructure;
