





















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage, DcwText, DcwButton } from '@atoms';
import { desktopNavigateTo } from '@/helpers';
import { ButtonVariants, ServicesItem } from '@/types';
import { useLocales } from '@/composables';

export const DcwServicesItem = defineComponent({
  name: 'DcwServicesItem',
  components: { DcwText, DcwImage, DcwButton },
  props: {
    data: {
      type: Object as PropType<ServicesItem>,
      required: true
    }
  },

  setup(props) {
    const { translation } = useLocales();

    const buttonProps = {
      text: translation.buttons.findOutMore,
      isLink: true,
      variant: ButtonVariants.TERTIARY,
      href: props.data.path
    };

    const clickHandler = () => {
      const href = props.data.path;
      if (href) {
        desktopNavigateTo(href, false);
      }
    };

    return { clickHandler, buttonProps };
  }
});

export default DcwServicesItem;
