
































import { defineComponent, PropType, watch, reactive, onMounted, ref, computed } from '@nuxtjs/composition-api';
import { DcwTimelineDot, DcwTimelineDesktopCard } from '../';
import { EventDot, TimetableCard, EventCardStates, Breakpoints } from '@/types';
import { formatDateString, isInEnum } from '@/helpers';
import { useBreakpoints } from '@/composables';

export const DcwTimelineDesktopWrapper = defineComponent({
  name: 'DcwTimelineDesktopWrapper',
  components: {
    DcwTimelineDot,
    DcwTimelineDesktopCard
  },
  props: {
    event: {
      type: Object as PropType<TimetableCard>,
      required: true
    },
    eventDot: {
      type: Object as PropType<EventDot>,
      required: true
    },
    state: {
      type: String as PropType<EventCardStates>,
      required: true,
      validator: (value: EventCardStates) => isInEnum(value, EventCardStates)
    },
    cardIndex: {
      type: Number,
      required: true
    },
    isLastOne: {
      type: Boolean,
      required: false
    },
    observer: {
      type: Object as PropType<{ observe: IntersectionObserver }>,
      required: true
    }
  },
  emits: ['transitionDone', 'more-click'],
  setup(props, { emit }) {
    const { currentWidth } = useBreakpoints();

    const animationState = reactive({
      bigVariantSize: false,
      hideContent: false,
      bigVariantContent: false,
      done: true
    });

    const forceCollapseOfDateProgress = ref(false);

    watch(
      () => props.eventDot,
      (newValue) => {
        if (newValue.index === newValue.closestEventIndex && newValue.activeEventIndex > newValue.closestEventIndex) {
          forceCollapseOfDateProgress.value = true;
          return;
        }

        forceCollapseOfDateProgress.value = false;
      }
    );

    watch(
      () => props.state,
      (newValue) => {
        if (newValue === EventCardStates.ACTIVE) {
          animationState.done = false;
          animationState.hideContent = true;

          setTimeout(() => {
            animationState.bigVariantContent = true;
            animationState.bigVariantSize = true;
          }, 300);

          setTimeout(() => {
            animationState.hideContent = false;
            animationState.done = true;
          }, 600);
        }
        if (newValue === EventCardStates.PREVIOUS) {
          animationState.done = false;
          animationState.hideContent = true;

          setTimeout(() => {
            animationState.bigVariantContent = false;
            animationState.bigVariantSize = false;
          }, 300);

          setTimeout(() => {
            animationState.hideContent = false;
            animationState.done = true;
            emit('transitionDone');
          }, 600);
        }
      }
    );

    const calibrateCardToBeInCenter = ref(false);

    const toggleCalibrate = () => {
      if (props.state === EventCardStates.ACTIVE) {
        calibrateCardToBeInCenter.value = true;
        setTimeout(() => {
          calibrateCardToBeInCenter.value = false;
        }, 0);
      }
    };

    watch(currentWidth, () => {
      toggleCalibrate();
    });

    onMounted(() => {
      if (props.state === 'active') {
        animationState.bigVariantContent = true;
        animationState.bigVariantSize = true;

        animationState.hideContent = false;
        animationState.done = true;
      }
      setTimeout(() => {
        toggleCalibrate();
      }, 100);
    });

    const tabletWidth = computed(() => {
      if (currentWidth.value < Breakpoints.MEDIUM && currentWidth.value > Breakpoints.SMALL) {
        const gapBetweenSlides = 24;
        return currentWidth.value - 2 * gapBetweenSlides;
      }
    });

    const timetableCardWrapperRef = ref<HTMLDivElement | null>(null);

    onMounted(() => {
      if (timetableCardWrapperRef.value && props.observer && process.client) {
        props.observer.observe.observe(timetableCardWrapperRef.value);
      }
      toggleCalibrate();
    });

    return {
      formatDateString,
      timetableCardWrapperRef,
      forceCollapseOfDateProgress,
      calibrateCardToBeInCenter,
      animationState,
      tabletWidth
    };
  }
});

export default DcwTimelineDesktopWrapper;
