














import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwCareerPathRecruiter } from './components/DcwCareerPathRecruiter.vue';
import { DcwCareerPathSlider } from './components/DcwCareerPathSlider.vue';
import { DcwNewHeadline, DcwGridWrapper } from '@atoms';
import { CareerPathSectionContent, HeadlineSubtitleSizes, HeadlineTypes, MainGridWrapperElements } from '@/types';

export const DcwCareerPathSection = defineComponent({
  name: 'DcwCareerPathSection',
  components: { DcwGridWrapper, DcwNewHeadline, DcwCareerPathSlider, DcwCareerPathRecruiter },
  props: {
    content: {
      type: Object as PropType<CareerPathSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { headlineProps, MainGridWrapperElements };
  }
});

export default DcwCareerPathSection;
