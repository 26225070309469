























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwBannerWithVideo, DcwProjectsSection, DcwCompanyEvents } from '@/components/Pages/Common';
import { getHydratedSection, isUnfinishedSectionEnabled } from '@/helpers';
import { DcwWideGrid } from '@atoms';
import {
  DcwBenefitsSection,
  DcwCareerPathSection,
  DcwJobOffersSection,
  DcwSuccessStoriesSection,
  DcwRemoteWork,
  DcwIntegrations,
  DcwBorn2Solve,
  DcwHybridWorkStructure,
  DcwOneOfUs
} from '@/components/Pages/Career';
import { CareerLayoutContent } from '@/types';

export const DcwCareerLayout = defineComponent({
  name: 'DcwCareerLayout',
  components: {
    DcwBannerWithVideo,
    DcwJobOffersSection,
    DcwWideGrid,
    DcwIntegrations: getHydratedSection(DcwIntegrations),
    DcwBorn2Solve: getHydratedSection(DcwBorn2Solve),
    DcwBenefitsSection: getHydratedSection(DcwBenefitsSection),
    DcwRemoteWork: getHydratedSection(DcwRemoteWork),
    DcwCareerPathSection: getHydratedSection(DcwCareerPathSection),
    DcwProjectsSection: getHydratedSection(DcwProjectsSection),
    DcwSuccessStoriesSection: getHydratedSection(DcwSuccessStoriesSection),
    DcwHybridWorkStructure: getHydratedSection(DcwHybridWorkStructure),
    DcwOneOfUs: getHydratedSection(DcwOneOfUs),
    DcwCompanyEvents: getHydratedSection(DcwCompanyEvents)
  },
  props: {
    content: {
      type: Object as PropType<CareerLayoutContent>,
      required: true
    }
  },
  setup() {
    return { isUnfinishedSectionEnabled };
  }
});

export default DcwCareerLayout;
