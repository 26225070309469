
















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwWideGrid } from '@atoms';
import { getHydratedSection } from '@/helpers';
import {
  DcwTABanner,
  DcwGlobalWork,
  DcwWorkWithPC,
  DcwCooperation,
  DcwExplanation,
  DcwCompanyLocation,
  DcwClientTestimonial
} from '@/components/Pages/TeamAugmentation';
import { TALayoutContent, TAPageSectionIds } from '@/types';

export const DcwTALayout = defineComponent({
  name: 'DcwTALayout',
  components: {
    DcwWideGrid,
    DcwTABanner,
    DcwWorkWithPC: getHydratedSection(DcwWorkWithPC),
    DcwGlobalWork: getHydratedSection(DcwGlobalWork),
    DcwExplanation: getHydratedSection(DcwExplanation),
    DcwCooperation: getHydratedSection(DcwCooperation),
    DcwCompanyLocation: getHydratedSection(DcwCompanyLocation),
    DcwClientTestimonial: getHydratedSection(DcwClientTestimonial)
  },
  props: {
    content: {
      type: Object as PropType<TALayoutContent>,
      required: true
    }
  },
  setup() {
    return { TAPageSectionIds };
  }
});

export default DcwTALayout;
