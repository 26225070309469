





















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  HeadlineTypes,
  HeadlineSubtitleSizes,
  CompanyLocationContent,
  HeadlineSubtitleSpacing,
  MainGridWrapperElements
} from '@/types';
import { DcwNewHeadline, DcwGridWrapper, DcwImage } from '@atoms';

export const DcwCompanyLocation = defineComponent({
  name: 'DcwCompanyLocation',
  components: { DcwNewHeadline, DcwGridWrapper, DcwImage },
  props: {
    content: {
      type: Object as PropType<CompanyLocationContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const itemHeadlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H4,
      styledAs: HeadlineTypes.H5,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    return { headlineProps, itemHeadlineProps, MainGridWrapperElements };
  }
});

export default DcwCompanyLocation;
