










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwButton, DcwImage } from '@atoms';
import { ButtonVariants, HeadlineSubtitleSizes, JobsOffersSectionContent, PagesLinks } from '@/types';
import { useLocales } from '@/composables';

export const DcwJobOfferCta = defineComponent({
  name: 'DcwJobOfferCta',
  components: { DcwNewHeadline, DcwButton, DcwImage },
  props: {
    content: {
      type: Object as PropType<JobsOffersSectionContent['cta']>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const scheduleButtonProps = {
      variant: ButtonVariants.TERTIARY,
      text: translation.buttons.allJobOffers,
      isLink: true,
      href: PagesLinks.JOBS
    };

    const ctaHeadlineProps = {
      ...props.content.headline,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { ctaHeadlineProps, scheduleButtonProps };
  }
});

export default DcwJobOfferCta;
