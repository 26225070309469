

















import { defineComponent, ref, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwCloseButton, DcwModal } from '@atoms';
import { DcwApplyNowCard } from '@/components/Pages/Position';
import { useLocales } from '@/composables';
import { ApplyCardData, ButtonVariants } from '@/types';

export const DcwApplyCardButton = defineComponent({
  name: 'DcwApplyCardButton',
  components: { DcwButton, DcwCloseButton, DcwModal, DcwApplyNowCard },
  props: {
    data: {
      type: Object as PropType<ApplyCardData>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const isModalShown = ref(false);

    const applyButtonProps = {
      icon: false,
      variant: ButtonVariants.PRIMARY,
      text: translation.buttons.applyNow
    };

    const applyButtonPropsLegacy = {
      variant: ButtonVariants.PRIMARY,
      isLink: true,
      href: props.data.clickUpLink,
      text: 'Apply via ClickUp',
      target: '_blank',
      rel: 'noopener',
      icon: false
    };

    return { translation, isModalShown, applyButtonProps, applyButtonPropsLegacy };
  }
});

export default DcwApplyCardButton;
