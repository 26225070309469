































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwClientTestimonialSlide } from './components/DcwClientTestimonialSlide.vue';
import { DcwGridWrapper, DcwSwiper, DcwSwiperPagination, DcwNewHeadline } from '@atoms';
import {
  Breakpoints,
  HeadlineTypes,
  SwiperPropsOptions,
  HeadlineSubtitleSizes,
  ReviewsSectionContent,
  MainGridWrapperElements
} from '@/types';
import { useSwiper } from '@/composables';
import { DcwSwiperButtons } from '@molecules';

export const DcwClientTestimonial = defineComponent({
  name: 'DcwClientTestimonial',
  components: { DcwSwiper, DcwGridWrapper, DcwNewHeadline, DcwSwiperButtons, DcwSwiperPagination, DcwClientTestimonialSlide },
  props: {
    content: {
      type: Object as PropType<ReviewsSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { swiperInfo, nextSlideHandler, prevSlideHandler, onSlideChange } = useSwiper({ length: props.content.slides.length });

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const swiperOptions: SwiperPropsOptions<ReviewsSectionContent['slides'][number]> = {
      swiperConfig: {
        slidesPerView: 1,
        speed: 500,
        loop: true,
        allowTouchMove: false
      },
      slides: props.content.slides,
      onSlideChange
    };

    return { swiperInfo, Breakpoints, headlineProps, swiperOptions, prevSlideHandler, nextSlideHandler, MainGridWrapperElements };
  }
});

export default DcwClientTestimonial;
