

























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwGridWrapper, DcwButton, DcwImage } from '@atoms';
import {
  PagesLinks,
  HeadlineTypes,
  ButtonVariants,
  TechnologyModel,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing
} from '@/types';
import { useLocales } from '@/composables';

export const DcwExtendYourTeam = defineComponent({
  name: 'DcwExtendYourTeam',
  components: { DcwGridWrapper, DcwNewHeadline, DcwButton, DcwImage },
  props: {
    content: {
      type: Object as PropType<TechnologyModel['extendTeam']>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const contentHeadlineProps = {
      ...props.content.banner.headline,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const itemHeadlineProps = {
      isTinted: true,
      type: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    const buttonProps = {
      isLink: true,
      href: PagesLinks.PROJECTS,
      variant: ButtonVariants.PRIMARY,
      text: translation.buttons.teamAugmentation
    };

    return { buttonProps, headlineProps, itemHeadlineProps, contentHeadlineProps };
  }
});

export default DcwExtendYourTeam;
