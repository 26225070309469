




















import { defineComponent, PropType, watch, ref, onMounted } from '@nuxtjs/composition-api';
import { DcwTimelineDot, DcwTimelineMobileCard } from '../';
import { EventDot, TimetableCard } from '@/types';
import { formatDateString } from '@/helpers';
import { useBreakpoints } from '@/composables';

export const DcwTimelineMobileWrapper = defineComponent({
  name: 'DcwTimelineMobileWrapper',
  components: {
    DcwTimelineDot,
    DcwTimelineMobileCard
  },
  props: {
    event: {
      type: Object as PropType<TimetableCard>,
      required: true
    },
    eventDot: {
      type: Object as PropType<EventDot>,
      required: true
    },
    observer: {
      type: Object as PropType<{ observe: IntersectionObserver }>,
      required: true
    }
  },
  setup(props) {
    const { currentWidth } = useBreakpoints();

    const timetableCardWrapperRef = ref<HTMLDivElement | null>(null);

    const calibrateCardToBeInCenter = ref(false);

    const toggleCalibrate = () => {
      if (props.eventDot.index === props.eventDot.activeEventIndex) {
        calibrateCardToBeInCenter.value = true;
        setTimeout(() => {
          calibrateCardToBeInCenter.value = false;
        }, 0);
      }
    };

    watch(currentWidth, () => {
      toggleCalibrate();
    });

    onMounted(() => {
      if (timetableCardWrapperRef.value && props.observer && process.client) {
        props.observer.observe.observe(timetableCardWrapperRef.value);
      }

      toggleCalibrate();
    });

    return {
      calibrateCardToBeInCenter,
      formatDateString,
      timetableCardWrapperRef
    };
  }
});

export default DcwTimelineMobileWrapper;
