



























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwNewHeadline, DcwText } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes, HWSAsideContent, HWSAsideContentType } from '@/types';

export const DcwAside = defineComponent({
  name: 'DcwAside',
  components: { DcwButton, DcwNewHeadline, DcwText },
  props: {
    content: {
      type: Array as PropType<HWSAsideContent>,
      required: true
    }
  },
  setup() {
    const headlineProps = {
      type: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    return { HWSAsideContentType, headlineProps };
  }
});

export default DcwAside;
