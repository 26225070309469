


























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage, DcwButton, DcwFlag } from '@atoms';
import { AboutOfficesSectionContent, ButtonVariants } from '@/types';
import { useLocales } from '@/composables';

export const DcwOfficeItem = defineComponent({
  name: 'DcwOfficeItem',
  components: { DcwImage, DcwButton, DcwFlag },
  props: {
    content: {
      type: Object as PropType<AboutOfficesSectionContent['items'][number]>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const buttonProps = {
      text: translation.buttons.viewOnMap,
      href: props.content.model.map,
      isLink: true,
      target: '_blank',
      variant: ButtonVariants.TERTIARY
    };

    return { buttonProps };
  }
});

export default DcwOfficeItem;
