



















































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwImage, DcwSelect, DcwFlag, DcwText, DcwButton } from '@atoms';
import { ButtonVariants, HeadlineSubtitleSizes, HeadlineTypes, HWSSectionContent } from '@/types';
import { useLocales } from '@/composables';

export const DcwHWSTabCoworking = defineComponent({
  name: 'DcwHWSTabCoworking',
  components: { DcwImage, DcwSelect, DcwNewHeadline, DcwFlag, DcwText, DcwButton },
  props: {
    content: {
      type: Object as PropType<HWSSectionContent['tabs']['coworking']['content']>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const activeCity = ref(0);

    const mainHeadline = {
      ...props.content.headline,
      styledAs: HeadlineTypes.H4,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const buttonProps = {
      text: translation.buttons.viewOnMap,
      isLink: true,
      target: '_blank',
      variant: ButtonVariants.TERTIARY
    };

    return { activeCity, mainHeadline, buttonProps };
  }
});

export default DcwHWSTabCoworking;
