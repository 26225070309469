

















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { OurStorySectionContent } from '@/types';

export const DcwStoryQuote = defineComponent({
  name: 'DcwStoryQuote',
  props: {
    content: {
      type: Object as PropType<OurStorySectionContent['quote']>,
      required: true
    }
  }
});

export default DcwStoryQuote;
