














import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwGridWrapper } from '@atoms';
import { BlogBannerContent, ButtonVariants, MainGridWrapperElements, PagesLinks } from '@/types';
import { useLocales } from '@/composables';

export const DcwBlogBanner = defineComponent({
  name: 'DcwBlogBanner',
  components: { DcwButton, DcwGridWrapper },
  props: {
    content: {
      type: Object as PropType<BlogBannerContent>,
      required: true
    }
  },
  setup() {
    const { translation } = useLocales();

    const backButtonProps = {
      text: translation.backButtons.blog,
      href: PagesLinks.BLOG,
      variant: ButtonVariants.TERTIARY,
      isLeft: true
    };

    return { MainGridWrapperElements, backButtonProps };
  }
});

export default DcwBlogBanner;
