export const MOBILE_CARD_WIDTH = 228;
export const TABLET_CARD_WIDTH = 744;
export const DESKTOP_CARD_WIDTH = 916;
export const NOT_ACTIVE_CARD_WIDTH = 221;

export const MOBILE_CARDS_GAP = 24;
export const MEDIUM_DESKTOP_CARDS_GAP = 80;

export const MOBILE_LEFT_PADDING = 24;
export const DESKTOP_LEFT_PADDING = 16;
