
































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwImage, DcwGridWrapper, DcwScrollSnap } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, MainGridWrapperElements, ManagementSectionContent } from '@/types';
import LinkedInIcon from '@/assets/brands/linkedin.svg';

export const DcwOurManagement = defineComponent({
  name: 'DcwOurManagement',
  components: { DcwNewHeadline, DcwImage, DcwGridWrapper, DcwScrollSnap, LinkedInIcon },
  props: {
    content: {
      type: Object as PropType<ManagementSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { headlineProps, MainGridWrapperElements };
  }
});

export default DcwOurManagement;
