














































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwTechnology, DcwImage } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes, ProjectTechnologies, ProjectTechSection } from '@/types';

export const DcwProjectTechnologies = defineComponent({
  name: 'DcwProjectTechnologies',
  components: { DcwNewHeadline, DcwTechnology, DcwImage },
  props: {
    content: {
      type: Object as PropType<ProjectTechSection>,
      required: true
    },
    technologies: {
      type: Object as PropType<ProjectTechnologies>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const othersHeadlineProps = {
      isTinted: true,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    return { headlineProps, othersHeadlineProps };
  }
});

export default DcwProjectTechnologies;
