import { BlogBody, isTextContent, SidebarItem } from '@/types';

interface UseBlogPostProps {
  content: BlogBody[];
}

interface UseBlogPost {
  formattedContent: BlogBody[];
  sidebarItems: SidebarItem[];
  textContent: string
}

export const useBlogPost = (props: UseBlogPostProps): UseBlogPost => {
  const sidebarItems: SidebarItem[] = [];
  let index = 0;

  const formattedContent = props.content.map((item) => {
    if (!isTextContent(item)) {
      return item;
    }

    const newItem = { ...item };
    const idAndClassRegex = /( class| id)="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gm; // eslint-disable-line no-useless-escape
    newItem.content = newItem.content.replaceAll(idAndClassRegex, '');

    const matches = [...newItem.content.matchAll(/<h2[^>]*>(.+?)<\/h2>/gm)];

    if (matches.length === 0) {
      return newItem;
    }

    let newContent = newItem.content;
    matches.forEach(([html, title]) => {
      const id = `section-${index}`;
      index += 1;

      const newHtml = html.replace('>', ` id="${id}">`);
      newContent = newContent.replace(html, newHtml);
      sidebarItems.push({ title, id });
    });

    newItem.content = newContent;
    return newItem;
  });

  const textContent = formattedContent
    .filter(isTextContent)
    .flatMap((el) => [el.content])
    .join(' ');

  return { formattedContent, sidebarItems, textContent };
};
