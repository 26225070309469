





















import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { useBreakpoints, useScrollSlideIntoView } from '@/composables';

export const DcwHWSNavigation = defineComponent({
  name: 'DcwHWSNavigation',
  props: {
    tabs: {
      type: Array as PropType<string[]>,
      required: true
    },
    active: {
      type: Number,
      default: 0
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { isMobile } = useBreakpoints();
    const rootRef = ref<HTMLDivElement | null>(null);

    const highlight = computed(() => {
      const tab = rootRef.value?.childNodes[props.active] as HTMLSpanElement | null;

      return {
        width: tab?.offsetWidth || 0,
        offset: tab ? tab.offsetLeft - (isMobile.value ? 24 : 0) : 0
      };
    });

    const onTabClick = (tabIndex: number) => {
      emit('update', tabIndex);
      useScrollSlideIntoView(rootRef, tabIndex);
    };

    return { rootRef, highlight, onTabClick };
  }
});

export default DcwHWSNavigation;
