import { ComputedRef, Ref, computed } from '@nuxtjs/composition-api';
import { DateTime } from 'luxon';
import { useBreakpoints } from '@/composables';
import { EventCardDate } from '@/types';
interface UseTimetableDateProgress {
  dateProgressInPixels: ComputedRef<number>;
  closestEventIndex: number;
}

interface UseTimetableDateProgressSetting {
  mobileGap: number;
  desktopGap: number;
  isHomepageVariant: boolean;
}

export const useTimetableDateProgress = (
  scrolledElementRef: Ref<HTMLElement | null>,
  eventsDots: EventCardDate[],
  activeCardIndex: Ref<number>,
  settings: UseTimetableDateProgressSetting = {
    mobileGap: 25,
    desktopGap: 64,
    isHomepageVariant: false
  }
): UseTimetableDateProgress => {
  const widthOfDotWrapper = computed(
    () => {
      if (scrolledElementRef.value && scrolledElementRef.value.children.length > 0) {
        return scrolledElementRef.value.children[0].clientWidth;
      } else {
        return 668;
      }
    }
  );

  const firstUpcomingEventIndex = eventsDots.findIndex((element) => {
    const elementDate = DateTime.fromISO(element.date);
    return elementDate > DateTime.now();
  });

  const closestEventIndex =
    firstUpcomingEventIndex !== -1
      ? firstUpcomingEventIndex - 1
      : eventsDots.length - 1;

  const { isMobile } = useBreakpoints();

  const dateProgressInPixels = computed(() => {
    let progressWidth = 0;

    const dateFactory = () => {
      const today = DateTime.now();

      const closestDateToToday = DateTime.fromISO(
        eventsDots[closestEventIndex].date
      );

      const endOfYear = DateTime.now().endOf('year');

      const nextEventDate = closestEventIndex + 1 < eventsDots.length
        ? DateTime.fromISO(eventsDots[closestEventIndex + 1].date)
        : endOfYear;

      const diffTodayAndClosestDate = today.diff(closestDateToToday, 'days');

      const diffDateBeforeTodayAndNextEvent = nextEventDate.diff(
        closestDateToToday,
        'days'
      );
      const diffLastEventAndEndOfYear = closestDateToToday.diff(
        endOfYear,
        'days'
      );

      return {
        diffTodayAndClosestDate,
        diffLastEventAndEndOfYear,
        diffDateBeforeTodayAndNextEvent
      };
    };

    const {
      diffTodayAndClosestDate,
      diffLastEventAndEndOfYear,
      diffDateBeforeTodayAndNextEvent
    } = dateFactory();

    const calculatePercentage = (
      elementWidth: number,
      amountOfDays: number
    ): number => (elementWidth / amountOfDays) * diffTodayAndClosestDate.days;

    if (closestEventIndex === eventsDots.length) {
      progressWidth += calculatePercentage(
        widthOfDotWrapper.value,
        diffLastEventAndEndOfYear.days
      );
    }

    if (settings.isHomepageVariant) {
      const smallCard = 221;
      const bigCard = 916;

      let cardSize = closestEventIndex === activeCardIndex.value ? bigCard : smallCard;

      if (isMobile.value) {
        cardSize = smallCard;
      }

      progressWidth += calculatePercentage(
        isMobile.value ? cardSize : cardSize + 126,
        diffDateBeforeTodayAndNextEvent.days
      );
    }

    if (!settings.isHomepageVariant) {
      progressWidth += calculatePercentage(
        widthOfDotWrapper.value,
        diffDateBeforeTodayAndNextEvent.days
      );
    }

    if (progressWidth === widthOfDotWrapper.value) {
      if (isMobile.value) {
        progressWidth += settings.mobileGap;
      } else {
        progressWidth += settings.desktopGap;
      }
    }

    return progressWidth;
  });

  return {
    dateProgressInPixels,
    closestEventIndex
  };
};
