

















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage, DcwLabel, DcwButton, DcwNewHeadline } from '@atoms';
import { BlogPost, ButtonVariants, HeadlineTypes } from '@/types';
import { desktopNavigateTo } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwBlogPost = defineComponent({
  name: 'DcwBlogPost',
  components: { DcwImage, DcwLabel, DcwButton, DcwNewHeadline },
  props: {
    post: {
      type: Object as PropType<BlogPost>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const buttonProps = {
      text: translation.buttons.readMore,
      isLink: true,
      href: props.post.link,
      variant: ButtonVariants.TERTIARY,
      target: '_blank',
      rel: 'noopener'
    };

    const headlineProps = {
      type: HeadlineTypes.H3,
      before: props.post.title,
      styledAs: HeadlineTypes.H4
    };

    const clickHandler = () => {
      desktopNavigateTo(props.post.link);
    };

    return { buttonProps, headlineProps, clickHandler };
  }
});

export default DcwBlogPost;
