var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dcw-timeline-dot",class:[{ 'dcw-timeline-dot--homepage': _vm.isHomepageVariant }, { 'dcw-timeline-dot--active': _vm.isActive }]},[_c('div',{staticClass:"dcw-timeline-dot__dot-line",class:[
      {
        'dcw-timeline-dot__dot-line--force-collapse':
          (_vm.isDotIndexMatchingClosestEventIndex && _vm.isDotIndexHigherThanActiveEventIndex) || _vm.canForceCollapse
      },
      {
        'dcw-timeline-dot__dot-line--full': _vm.isDotIndexLowerThanClosestEventIndex && _vm.isDotIndexLowerThanActiveEventIndex
      }
    ]}),_vm._v(" "),_c('div',{staticClass:"dcw-timeline-dot__line",class:{ 'dcw-timeline-dot__line--last-one': _vm.isLastOne }}),_vm._v(" "),_c('div',{class:[
      'dcw-event-dot',
      {
        'dcw-event-dot--active': _vm.isDotIndexMatchingActiveEventIndex
      }
    ]}),_vm._v(" "),_c('div',{staticClass:"dcw-timeline-dot__dot-date"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }