











































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage, DcwButton, DcwNewHeadline } from '@atoms';
import { DcwContentBlock } from '@molecules';
import PlayIcon from '@/assets/icons/streamline-icons/play-icon.svg';
import { OneOfUsSlide, ContentBlockSpacings, HeadlineSubtitleSizes, HeadlineTypes, HeadlineSubtitleSpacing } from '@/types';

export const DcwOneOfUsSlide = defineComponent({
  name: 'DcwOneOfUsSlide',
  components: { PlayIcon, DcwImage, DcwButton, DcwNewHeadline, DcwContentBlock },
  props: {
    slide: {
      type: Array as PropType<OneOfUsSlide>,
      required: true
    }
  },
  setup() {
    const offsetEntry = (offset: number, index: number) => [`--member-offset-variant-${index}`, `${offset}px`];

    const headlineProps = {
      type: HeadlineTypes.H3,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      mobileStyledAs: HeadlineTypes.H4,
      mobileSubtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    return { offsetEntry, ContentBlockSpacings, HeadlineSubtitleSizes, headlineProps, HeadlineTypes };
  }
});

export default DcwOneOfUsSlide;
