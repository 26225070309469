

















import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwTimelineSlider } from './components';
import { events } from './DcwTimeline.const';
import { DcwGridWrapper, DcwSwiperPagination, DcwNewHeadline } from '@atoms';
import { Breakpoints, HeadlineSubtitleSizes, HeadlineTypes, MainGridWrapperElements, TimelineSectionContent } from '@/types';
import { useBreakpoints } from '@/composables';

export const DcwExploreOurStory = defineComponent({
  name: 'DcwExploreOurStory',
  components: { DcwNewHeadline, DcwGridWrapper, DcwTimelineSlider, DcwSwiperPagination },
  props: {
    content: {
      type: Object as PropType<TimelineSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { currentWidth } = useBreakpoints();
    const amountOfSlides = computed(() => events.length - 1);
    const currentCardIndex = ref(currentWidth.value >= Breakpoints.SMALL ? 1 : 0);
    const progress = computed(() => currentCardIndex.value / amountOfSlides.value);

    const isThrottling = ref(false);

    const nextSlideHandler = () => {
      currentCardIndex.value += 1;
    };

    const prevSlideHandler = () => {
      currentCardIndex.value -= 1;
    };

    const slideChangeHandler = (slide: number) => {
      currentCardIndex.value = slide;
    };

    const debounceThrottling = () => {
      setTimeout(() => {
        isThrottling.value = false;
      }, 1000);
    };

    const handleChangeSlide = (isNext: boolean) => {
      const isSlideChangePossible = isNext ? amountOfSlides.value > currentCardIndex.value : currentCardIndex.value > 0;
      if (!isSlideChangePossible) {
        return;
      }

      isThrottling.value = true;

      isNext ? nextSlideHandler() : prevSlideHandler();

      debounceThrottling();
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    watch(currentWidth, (newValue, oldValue) => {
      if (oldValue > Breakpoints.SMALL && newValue >= Breakpoints.SMALL) {
        currentCardIndex.value = 0;
        return;
      }

      if (oldValue < Breakpoints.SMALL && newValue >= Breakpoints.SMALL) {
        currentCardIndex.value = 1;
      }
    });

    return {
      events,
      progress,
      isThrottling,
      headlineProps,
      amountOfSlides,
      currentCardIndex,
      handleChangeSlide,
      slideChangeHandler,
      MainGridWrapperElements
    };
  }
});

export default DcwExploreOurStory;
