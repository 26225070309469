









































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwButton, DcwImage, DcwText, DcwTooltip } from '@atoms';
import { ButtonVariants, Directions, MainGridWrapperElements, PagesLinks, ProjectBannerContent } from '@/types';
import { useLocales } from '@/composables';

export const DcwProjectBanner = defineComponent({
  name: 'DcwProjectBanner',
  components: { DcwGridWrapper, DcwButton, DcwImage, DcwText, DcwTooltip },
  props: {
    content: {
      type: Object as PropType<ProjectBannerContent>,
      required: true
    },
    isWhite: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { translation } = useLocales();

    const backLinkProps = {
      text: translation.backButtons.projects,
      variant: ButtonVariants.TERTIARY,
      href: PagesLinks.PROJECTS,
      isLink: true,
      isLeft: true
    };

    return { backLinkProps, MainGridWrapperElements, Directions, translation };
  }
});

export default DcwProjectBanner;
