
































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwButton, DcwNewHeadline, DcwGridWrapper, DcwImage, DcwScrollSnap } from '@atoms';
import { DcwVideoModal } from '@molecules';
import {
  Born2SolveSectionContent,
  ButtonVariants,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing,
  HeadlineTypes,
  MainGridWrapperElements
} from '@/types';
import CubeIcon from '@/assets/cube.svg';

export const DcwBorn2Solve = defineComponent({
  name: 'DcwBorn2Solve',
  components: { DcwButton, DcwGridWrapper, DcwImage, CubeIcon, DcwScrollSnap, DcwVideoModal, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<Born2SolveSectionContent>,
      required: true
    }
  },
  setup(props) {
    const showModal = ref(false);

    const mainHeadlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const othersHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H5,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    const firstButtonProps = {
      text: props.content.firstButton,
      isPlay: true
    };

    const secondButtonProps = {
      isLink: true,
      variant: ButtonVariants.TERTIARY,
      ...props.content.secondButton
    };

    return { showModal, firstButtonProps, secondButtonProps, mainHeadlineProps, othersHeadlineProps, MainGridWrapperElements };
  }
});

export default DcwBorn2Solve;
