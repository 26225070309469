












import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwCompanyEvents } from '@/components/Pages/Common';
import { DcwContactSection, DcwLocationSection } from '@/components/Pages/Contact';
import { ContactLayoutContent } from '@/types';
import { getHydratedSection, isUnfinishedSectionEnabled } from '@/helpers';
import { DcwWideGrid } from '@atoms';

export const DcwContactLayout = defineComponent({
  name: 'DcwContactLayout',
  components: {
    DcwContactSection,
    DcwWideGrid,
    DcwCompanyEvents: getHydratedSection(DcwCompanyEvents),
    DcwLocationSection: getHydratedSection(DcwLocationSection)
  },
  props: {
    content: {
      type: Object as PropType<ContactLayoutContent>,
      required: true
    }
  },
  setup() {
    return { isUnfinishedSectionEnabled };
  }
});

export default DcwContactLayout;
