

































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwCountriesSlider } from './components/DcwCountriesSlider.vue';
import { DcwImage, DcwNewHeadline, DcwGridWrapper } from '@atoms';
import { HeadlineTypes, HeadlineSubtitleSizes, WeWorkForSectionContent, HeadlineSubtitleSpacing } from '@/types';

export const DcwWeWorkForSection = defineComponent({
  name: 'DcwWeWorkForSection',
  components: { DcwImage, DcwNewHeadline, DcwGridWrapper, DcwCountriesSlider },
  props: {
    content: {
      type: Object as PropType<WeWorkForSectionContent>,
      required: true
    }
  },
  setup(props) {
    const activeIndex = ref(0);
    const activeCountry = computed(() => props.content.countries[activeIndex.value]);

    const updateSlide = (index: number) => {
      activeIndex.value = index;
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2
    };

    const activeCountryHeadlineProps = computed(() => ({
      ...activeCountry.value.headline,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      type: HeadlineTypes.H3,
      isTinted: true
    }));

    const infoHeadlineProps = {
      isBold: true,
      type: HeadlineTypes.P,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    return {
      updateSlide,
      activeIndex,
      headlineProps,
      activeCountry,
      infoHeadlineProps,
      activeCountryHeadlineProps
    };
  }
});

export default DcwWeWorkForSection;
