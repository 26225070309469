

































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwTimelineGallery } from '../';
import { DcwLabel, DcwButton, DcwNewHeadline } from '@atoms';
import { DcwContentBlock } from '@molecules';
import { ButtonVariants, ContentBlockSpacings, LabelTypes, TimelineSectionContent, TimetableCardVariant } from '@/types';
import { isInEnum } from '@/helpers';

interface AnimationState {
  bigVariantSize: boolean;
  hideContent: boolean;
  bigVariantContent: boolean;
  done: boolean;
}

export const DcwTimelineDesktopCard = defineComponent({
  name: 'DcwTimelineDesktopCard',
  components: { DcwLabel, DcwContentBlock, DcwNewHeadline, DcwButton, DcwTimelineGallery },
  props: {
    content: {
      type: Object as PropType<TimelineSectionContent['events'][number]>,
      required: true
    },
    animationState: {
      type: Object as PropType<AnimationState>,
      required: true
    },
    variant: {
      type: String as PropType<TimetableCardVariant>,
      default: TimetableCardVariant.TWO_COLUMNS,
      validator: (value: TimetableCardVariant) => isInEnum(value, TimetableCardVariant)
    },
    tabletWidth: {
      type: Number,
      required: false
    }
  },
  emits: ['more-click'],
  setup() {
    return { TimetableCardVariant, ButtonVariants, ContentBlockSpacings, LabelTypes };
  }
});

export default DcwTimelineDesktopCard;
