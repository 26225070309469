

























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ListWithIcons, HtmlWrappers } from '@/types';

export const DcwListWithIcons = defineComponent({
  name: 'DcwListWithIcons',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array as PropType<ListWithIcons[]>,
      required: true
    },
    linkColor: String
  },
  setup() {
    return { HtmlWrappers };
  }
});

export default DcwListWithIcons;
