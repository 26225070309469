













import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwCtaCard } from './components/DcwCard.vue';
import { DcwGridWrapper, DcwNewHeadline, DcwButton } from '@atoms';
import { ButtonVariants, HeadlineSubtitleSizes, HeadlineTypes, TechnologyCtaSectionContent } from '@/types';
import Map from '@/assets/technology-page/cta/bg.svg';

export const DcwTechnologyCta = defineComponent({
  name: 'DcwTechnologyCta',
  components: { DcwGridWrapper, DcwCtaCard, DcwNewHeadline, DcwButton, Map },
  props: {
    content: {
      type: Object as PropType<TechnologyCtaSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const buttonProps = {
      ...props.content.button,
      isLink: !!props.content.button.href,
      variant: ButtonVariants.TERTIARY
    };

    return { headlineProps, buttonProps };
  }
});

export default DcwTechnologyCta;
