




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwImage, DcwButton } from '@atoms';
import {
  HeadlineTypes,
  ButtonVariants,
  HeadlineSubtitleSizes,
  MainGridWrapperElements,
  TechnologyBannerSectionContent
} from '@/types';

export const DcwBanner = defineComponent({
  name: 'DcwBanner',
  components: { DcwGridWrapper, DcwNewHeadline, DcwImage, DcwButton },
  props: {
    content: {
      type: Object as PropType<TechnologyBannerSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      isTinted: true,
      type: HeadlineTypes.H1,
      mobileStyledAs: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      before: props.content.headline.before + '\n',
      highlight: `${props.content.name} ${props.content.headline.highlight}`
    };

    const firstButtonProps = {
      ...props.content.firstButton,
      variant: ButtonVariants.PRIMARY,
      isLink: !!props.content.firstButton.href
    };

    const secondButtonProps = {
      ...props.content.secondButton,
      variant: ButtonVariants.TERTIARY,
      isLink: !!props.content.secondButton.href
    };

    return { headlineProps, firstButtonProps, secondButtonProps, MainGridWrapperElements };
  }
});

export default DcwBanner;
