









import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwImage } from '@atoms';
import { HeadlineTypes, HeadlineSubtitleSizes, HomeHeroSectionContent, HeadlineSubtitleSpacing } from '@/types';

export const DcwManWithCard = defineComponent({
  name: 'DcwManWithCard',
  components: { DcwImage, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<HomeHeroSectionContent['person']>,
      required: true
    }
  },
  setup(props) {
    const badgeProps = {
      before: `${props.content.model.name} ${props.content.model.surname}`,
      styledAs: HeadlineTypes.H5,
      type: HeadlineTypes.P,
      subtitle: props.content.model.position,
      isTinted: true,
      subtitleSpacing: HeadlineSubtitleSpacing.NO,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    return { badgeProps };
  }
});

export default DcwManWithCard;
