











import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwScrollSnap } from '@atoms';
import { DcwPersonCard } from '@molecules';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes, Project } from '@/types';

export const DcwYourFutureTeam = defineComponent({
  name: 'DcwYourFutureTeam',
  components: { DcwPersonCard, DcwNewHeadline, DcwScrollSnap },
  props: {
    data: {
      type: Object as PropType<Project['sections']['team']>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.data.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    return { headlineProps };
  }
});

export default DcwYourFutureTeam;
