











import { computed, defineComponent, onMounted, PropType, ref, useRoute } from '@nuxtjs/composition-api';
import { DcwWideGrid } from '@atoms';
import { DcwVideoModal } from '@molecules';
import { DcwVideoListingHero, DcwInterviews, DcwIntegrationVideos } from '@/components/Pages/VideoListing';
import { VideoListingContent } from '@/types';
import { videosService } from '@/service/model';

export const DcwVideoListingLayout = defineComponent({
  name: 'DcwVideoListingLayout',
  components: { DcwWideGrid, DcwVideoListingHero, DcwInterviews, DcwIntegrationVideos, DcwVideoModal },
  props: {
    content: {
      type: Object as PropType<VideoListingContent>,
      required: true
    }
  },
  setup() {
    const videoId = ref('');
    const isModalShown = ref(false);

    const video = computed(() => {
      const id = useRoute().value.query.video;

      console.log(id);

      if (!id || Array.isArray(id)) {
        return;
      }

      try {
        const neededVideo = videosService.getContentById(id);
        return neededVideo.videoId;
      } catch (e) {}
    });

    onMounted(() => {
      const id = useRoute().value.query.video;

      if (!id || Array.isArray(id)) {
        return;
      }

      try {
        const neededVideo = videosService.getContentById(id);
        console.log(neededVideo);
        videoId.value = neededVideo.id;
      } catch (e) {}
    });

    return { video, isModalShown, videoId };
  }
});

export default DcwVideoListingLayout;
