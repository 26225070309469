


















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwImage, DcwNewHeadline } from '@atoms';
import { MainGridWrapperElements, HeadlineTypes, HeadlineContent, SVGIcon } from '@/types';

export const DcwCaseFeatured = defineComponent({
  name: 'DcwCaseFeatured',
  components: { DcwGridWrapper, DcwImage, DcwNewHeadline },
  props: {
    as: {
      type: String,
      default: MainGridWrapperElements.FOOTER
    },
    headline: {
      type: Object as PropType<HeadlineContent>,
      required: true
    },
    icons: {
      type: Array as PropType<SVGIcon[]>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.headline,
      type: HeadlineTypes.H3
    };

    return { HeadlineTypes, headlineProps };
  }
});

export default DcwCaseFeatured;
