















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwScrollSnap, DcwWideGrid } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, MainGridWrapperElements, WorkingWithPCSectionContent } from '@/types';

export const DcwWorkWithPC = defineComponent({
  name: 'DcwWorkWithPC',
  components: { DcwGridWrapper, DcwNewHeadline, DcwScrollSnap, DcwWideGrid },
  props: {
    content: {
      type: Object as PropType<WorkingWithPCSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      type: HeadlineTypes.H2,
      isTinted: true
    };

    const itemHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { headlineProps, itemHeadlineProps, MainGridWrapperElements };
  }
});

export default DcwWorkWithPC;
