
























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwOfficeItem } from './components/DcwOfficeItem.vue';
import { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination, DcwSwiper } from '@atoms';
import { AboutOfficesSectionContent, Breakpoints, SwiperPropsOptions, HeadlineTypes, HeadlineSubtitleSizes } from '@/types';
import { useSwiper } from '@/composables';

export const DcwOffices = defineComponent({
  name: 'DcwOffices',
  components: { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination, DcwSwiper, DcwOfficeItem },
  props: {
    content: {
      type: Object as PropType<AboutOfficesSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { nextSlideHandler, prevSlideHandler, swiperInfo, onSlideChange } = useSwiper({ length: props.content.items.length });

    const swiperOptions: SwiperPropsOptions<AboutOfficesSectionContent['items'][number]> = {
      swiperConfig: {
        slidesPerView: 1,
        spaceBetween: 32,
        speed: 300,
        loop: true,
        breakpoints: {
          [Breakpoints.SMALL_MOBILE]: {
            slidesPerView: 1.2398,
            spaceBetween: 24
          },
          [Breakpoints.SMALL]: {
            slidesPerView: 2,
            spaceBetween: 32
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 32
          }
        }
      },
      slides: props.content.items,
      onSlideChange
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { swiperInfo, headlineProps, HeadlineTypes, swiperOptions, onSlideChange, nextSlideHandler, prevSlideHandler };
  }
});

export default DcwOffices;
