































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwText } from '@atoms';
import { DcwList } from '@molecules';
import { HeadlineTypes, JobPositionSectionContent, TextPageListType } from '@/types';

export const DcwPositionDescription = defineComponent({
  name: 'DcwPositionDescription',
  components: { DcwList, DcwNewHeadline, DcwText },
  props: {
    content: {
      type: Array as PropType<JobPositionSectionContent[]>,
      required: true
    }
  },
  setup() {
    return { TextPageListType, HeadlineTypes };
  }
});

export default DcwPositionDescription;
