











































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwAccordion, DcwImage, DcwText } from '@atoms';
import IconSend from '@/assets/icons/send.svg';
import IconStyleBold from '@/assets/icons/style-bold.svg';
import IconStyleItalic from '@/assets/icons/style-italic.svg';
import IconStyleStrikethrough from '@/assets/icons/style-strikethrough.svg';
import { HWSSectionContent } from '@/types';

export const DcwHWSTabOrganization = defineComponent({
  name: 'DcwHWSTabOrganization',
  components: {
    DcwText,
    DcwImage,
    IconSend,
    DcwAccordion,
    IconStyleBold,
    IconStyleItalic,
    IconStyleStrikethrough
  },
  props: {
    content: {
      type: Object as PropType<HWSSectionContent['tabs']['organization']['content']>,
      required: true
    }
  }
});

export default DcwHWSTabOrganization;
