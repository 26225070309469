var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DcwGridWrapper',{ref:"sectionRef",staticClass:"dcw-meet-our-teams",attrs:{"id":_vm.HomePageSectionIds.MEET_OUR_TEAMS}},[_c('div',{staticClass:"dcw-meet-our-teams__heading-wrapper"},[_c('div',{staticClass:"dcw-meet-our-teams__title-wrapper"},[_c('div',{staticClass:"dcw-meet-our-teams__tooltip"},[_c('DcwTooltip',{attrs:{"path":_vm.PagesLinks.CAREER,"direction":_vm.Directions.BOTTOM}},[_c('span',[_vm._v(_vm._s(_vm.translation.tooltip.weAreHiring))]),_vm._v(" "),_c('DcwImage',_vm._b({staticClass:"dcw-meet-our-teams__tooltip-emoji"},'DcwImage',_vm.WAVE_EMOJI,false))],1)],1),_vm._v(" "),_c('DcwNewHeadline',_vm._b({staticClass:"dcw-meet-our-teams__title",attrs:{"subtitle":null}},'DcwNewHeadline',_vm.headlineProps,false)),_vm._v(" "),_c('div',{staticClass:"dcw-meet-our-teams__tooltip"},[_c('DcwTooltip',{attrs:{"path":_vm.PagesLinks.CAREER}},[_c('span',[_vm._v(_vm._s(_vm.translation.tooltip.weAreHiring))]),_vm._v(" "),_c('DcwImage',_vm._b({staticClass:"dcw-meet-our-teams__tooltip-emoji"},'DcwImage',_vm.WAVE_EMOJI,false))],1)],1)],1),_vm._v(" "),_c('p',{staticClass:"dcw-meet-our-teams__heading-paragraph"},[_vm._v("\n      "+_vm._s(_vm.content.headline.subtitle)+"\n    ")])]),_vm._v(" "),_c('div',{directives:[{name:"safari-overflow-container",rawName:"v-safari-overflow-container"}],ref:"rootRef",staticClass:"dcw-meet-our-teams__buttons",style:({
      '--highlight-width': ((_vm.buttonHighlight.width) + "px"),
      '--highlight-offset': ((_vm.buttonHighlight.offset) + "px")
    })},[_vm._l((_vm.content.items),function(nav,index){return [_c('DcwButton',{key:("team-button-" + index),staticClass:"dcw-meet-our-teams__button",class:{ 'dcw-meet-our-teams__button--active': _vm.swiperInfo.activeIndex === index },attrs:{"text":nav.navText,"icon":false},on:{"click":function($event){return _vm.onTabClick(index)}}})]})],2),_vm._v(" "),_c('DcwSwiper',{directives:[{name:"safari-overflow-container",rawName:"v-safari-overflow-container",value:('dcw-meet-our-teams__tabs--safari-fix'),expression:"'dcw-meet-our-teams__tabs--safari-fix'"}],ref:"swiperRef",staticClass:"dcw-meet-our-teams__tabs",attrs:{"options":_vm.swiperOptions,"active-slide":_vm.swiperInfo.activeSlide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var slide = ref.slide;
    var index = ref.index;
return [_c('DcwMeetOurTeamTab',{style:({
          '--dcw-color-primary': _vm.getColor(index).hundred,
          '--dcw-color-primary-light': _vm.getColor(index).thirty,
          '--dcw-color-secondary-light': _vm.getColor(index).five
        }),attrs:{"content":slide}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }