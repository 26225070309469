























import { defineComponent, onMounted, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwCaseSlide } from './DcwCaseSlide.vue';
import { DcwSwiper } from '@atoms';
import { DcwSwiperButtons } from '@molecules';
import { CaseStudiesSlide, SwiperPropsOptions } from '@/types';
import { useSwiper } from '@/composables';

export const DcwCaseSlider = defineComponent({
  name: 'DcwCaseSlider',
  components: { DcwCaseSlide, DcwSwiper, DcwSwiperButtons },
  props: {
    slides: {
      type: Array as PropType<CaseStudiesSlide[]>,
      required: true
    }
  },
  emits: ['dcw-slide-change'],
  setup(props, context) {
    const canvasRef = ref<HTMLCanvasElement | null>(null);
    const { onSlideChange, nextSlideHandler, prevSlideHandler, swiperInfo } = useSwiper();

    watch(
      () => swiperInfo.activeIndex,
      (index: number) => context.emit('dcw-slide-change', index)
    );

    onMounted(() => {
      const ctx = canvasRef.value?.getContext('2d');
      const imageData = ctx?.createImageData(1440, 640);

      if (imageData) {
        const buffer = new Uint32Array(imageData.data.buffer);
        const pixelValues = [0, 0, 0, 128, 128, 128, 255, 255].map((num) => (num ? (num << 24) + 0xFFFFFF : num));

        buffer.set(buffer.map(() => pixelValues[(Math.random() * 8) & 7]));

        ctx?.putImageData(imageData, 0, 0);
      }
    });

    const swiperOptions: SwiperPropsOptions<CaseStudiesSlide> = {
      swiperConfig: {
        slidesPerView: 1,
        spaceBetween: 1000,
        speed: 1000,
        loop: true
      },
      onSlideChange,
      slides: props.slides
    };

    return { canvasRef, swiperInfo, swiperOptions, nextSlideHandler, prevSlideHandler };
  }
});

export default DcwCaseSlider;
