
























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { useBlogPost } from './use-blog-post';
import { DcwRelatedPosts } from './components';
import { DcwGridWrapper } from '@atoms';
import { DcwTextPageSidebar, DcwPageInfo } from '@molecules';
import { DcwBlogContent, DcwBlogBanner } from '@/components/Pages/Blog';
import { MainGridWrapperElements, BlogBannerContent, BlogPostWithRelatedPosts, ProjectSelectors, PagesLinks } from '@/types';
import { formatDateString } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwBlogPostLayout = defineComponent({
  name: 'DcwBlogPostLayout',
  components: { DcwGridWrapper, DcwTextPageSidebar, DcwBlogContent, DcwRelatedPosts, DcwBlogBanner, DcwPageInfo },
  props: {
    content: {
      type: Object as PropType<BlogPostWithRelatedPosts>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const contentRef = ref(null);
    const { formattedContent, sidebarItems, textContent } = useBlogPost({ content: props.content.body });

    const bannerContent: BlogBannerContent = {
      name: props.content.name,
      thumbnail: props.content.thumbnail
    };

    const sidebarData = {
      sections: sidebarItems
    };

    const pageInfoProps = {
      details: [props.content.author, formatDateString(props.content.dateOfPublication)],
      badges: [{ name: translation.pagesNames.blog, link: PagesLinks.BLOG }],
      text: textContent,
      contentWrapperClass: ProjectSelectors.BLOG_CONTENT_WRAPPER
    };

    return { contentRef, formattedContent, sidebarData, MainGridWrapperElements, bannerContent, pageInfoProps };
  }
});

export default DcwBlogPostLayout;
