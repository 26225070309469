













import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwScrollSnap } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes, PositionReasonsToJoinSectionContent } from '@/types';

export const DcwReasonsToJoin = defineComponent({
  name: 'DcwReasonsToJoin',
  components: { DcwNewHeadline, DcwScrollSnap },
  props: {
    content: {
      type: Object as PropType<PositionReasonsToJoinSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    const itemHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H6,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { HeadlineTypes, HeadlineSubtitleSizes, headlineProps, itemHeadlineProps };
  }
});

export default DcwReasonsToJoin;
