













import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { DcwScrollSnap } from '@atoms';
import { JobOfferWithId } from '@/types';

export const DcwJobsWrapper = defineComponent({
  name: 'DcwJobsWrapper',
  components: { DcwScrollSnap },
  props: {
    offers: {
      type: Array as PropType<JobOfferWithId[]>,
      required: true
    }
  },
  setup(props) {
    const sortedOffersArray = computed(() => {
      return [...props.offers].sort((a) => (a.featured ? -1 : 1));
    });

    return { sortedOffersArray };
  }
});

export default DcwJobsWrapper;
