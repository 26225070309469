












import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwImage, DcwText, DcwScrollSnap } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, PositionBenefitsSection } from '@/types';

export const DcwPositionBenefits = defineComponent({
  name: 'DcwPositionBenefits',
  components: { DcwNewHeadline, DcwImage, DcwText, DcwScrollSnap },
  props: {
    content: {
      type: Object as PropType<PositionBenefitsSection>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.NO
    };

    return { headlineProps };
  }
});

export default DcwPositionBenefits;
