



























































/* eslint-disable max-len */
import { defineComponent, computed, ref, PropType } from '@nuxtjs/composition-api';
import { DcwLocationSlider } from './components/DcwLocationSlider.vue';
import { DcwGridWrapper, DcwNewHeadline, DcwImage, DcwText } from '@atoms';
import {
  HeadlineTypes,
  ButtonVariants,
  HtmlInlineElements,
  HeadlineSubtitleSizes,
  ContactLocationsSectionContent
} from '@/types';
import { useBreakpoints, useLocales } from '@/composables';

export const DcwLocationSection = defineComponent({
  name: 'DcwLocationSection',
  components: { DcwText, DcwImage, DcwNewHeadline, DcwGridWrapper, DcwLocationSlider },
  props: {
    content: {
      type: Object as PropType<ContactLocationsSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { isMobile } = useBreakpoints();
    const { translation } = useLocales();
    const activeIndex = ref(0);
    const isModalShown = ref(false);
    const activeLocation = computed(() => {
      return props.content.locations[activeIndex.value];
    });

    const updateSlide = (index: number) => {
      activeIndex.value = index;
    };

    const buttonProps = {
      text: translation.buttons.scheduleACall,
      variant: ButtonVariants.SECONDARY,
      isSmall: true
    };

    const mainHeadlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2
    };

    const secondHeadlineSubtitleProps = {
      text: props.content.secondHeadline.subtitle,
      size: HeadlineSubtitleSizes.SMALL,
      tinted: true
    };

    const secondHeadlineProps = {
      ...props.content.secondHeadline,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      styledAs: HeadlineTypes.H3,
      mobileStyledAs: HeadlineTypes.H4,
      isTinted: true
    };

    return {
      activeIndex,
      HeadlineTypes,
      activeLocation,
      updateSlide,
      buttonProps,
      HtmlInlineElements,
      mainHeadlineProps,
      secondHeadlineProps,
      secondHeadlineSubtitleProps,
      isMobile,
      isModalShown
    };
  }
});

export default DcwLocationSection;
