






















import { computed, defineComponent, onMounted, PropType, ref } from '@nuxtjs/composition-api';
import * as SwiperClass from 'swiper';
import { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination } from '@atoms';
import { DcwPeopleSlider } from '@organisms';
import { useLocales, useSwiper } from '@/composables';
import { Breakpoints, HeadlineSubtitleSizes, HeadlineTypes, PagesLinks, SuccessStoriesSectionContent } from '@/types';

export const DcwSuccessStoriesSection = defineComponent({
  name: 'DcwSuccessStoriesSection',
  components: { DcwNewHeadline, DcwGridWrapper, DcwPeopleSlider, DcwSwiperPagination },
  props: {
    content: {
      type: Object as PropType<SuccessStoriesSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const transformedStories = computed(() => {
      return props.content.stories.map((item) => ({
        name: item.name,
        position: item.position,
        img: item.images.slider,
        description: item.shortStory,
        videoId: item.videoId,
        button: {
          href: `${PagesLinks.SUCCESS_STORIES}/${item.id}`,
          text: translation.buttons.readSuccessStory
        }
      }));
    });

    const { nextSlideHandler, prevSlideHandler, swiperInfo } = useSwiper();

    const slidesGroupCount = ref(0);
    const length = ref(props.content.stories.length);

    const setLength = () => {
      if (window.innerWidth < Breakpoints.MEDIUM_DESKTOP && slidesGroupCount.value !== 2) {
        slidesGroupCount.value = 2;
        length.value = Math.round(props.content.stories.length / slidesGroupCount.value);
      }

      if (window.innerWidth >= Breakpoints.MEDIUM_DESKTOP && slidesGroupCount.value !== 3) {
        slidesGroupCount.value = 3;
        length.value = Math.round(props.content.stories.length / slidesGroupCount.value);
      }
    };

    onMounted(() => {
      setLength();
      window.addEventListener('resize', () => {
        setLength();
      });
    });

    const slideChangeHandler = (i: SwiperClass.Swiper) => {
      const activeIndex = i.realIndex;
      let sign = 1;
      if (window.innerWidth >= Breakpoints.MEDIUM_DESKTOP) {
        sign = 2;
      }

      if (activeIndex === 0) {
        swiperInfo.progress = 0;
      } else {
        const currentValue = (activeIndex + 1 * sign) / props.content.stories.length;
        const lastValue = (props.content.stories.length - 1) / props.content.stories.length;
        if (currentValue === lastValue) {
          swiperInfo.progress = 1;
        } else {
          swiperInfo.progress = (activeIndex + 1) / props.content.stories.length;
        }
      }
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { length, swiperInfo, headlineProps, prevSlideHandler, nextSlideHandler, transformedStories, slideChangeHandler };
  }
});

export default DcwSuccessStoriesSection;
