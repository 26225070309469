





















































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwButton, DcwImage } from '@atoms';
import { DcwSectionWithHtml, DcwTextPageSidebar } from '@molecules';
import {
  MainGridWrapperElements,
  HeadlineSubtitleSizes,
  ProjectLayoutContent,
  ProjectSectionsId,
  ProjectSelectors,
  HeadlineTypes,
  ProjectColors,
  CssVariables,
  SidebarData,
  LayoutCta
} from '@/types';
import { useStyleSheet } from '@/composables';
import { getHEXOpacityColor, getHydratedSection } from '@/helpers';
import { DcwTextPageCTA } from '@/components/Pages/Common';
import {
  DcwProjectTechnologies,
  DcwProjectPositions,
  DcwYourFutureTeam,
  DcwProjectBanner,
  DcwOtherProjects
} from '@/components/Pages/Project';

export const DcwProjectLayout = defineComponent({
  name: 'DcwProjectLayout',
  components: {
    DcwImage,
    DcwButton,
    DcwNewHeadline,
    DcwGridWrapper,
    DcwProjectBanner,
    DcwTextPageSidebar,
    DcwProjectTechnologies: getHydratedSection(DcwProjectTechnologies),
    DcwProjectPositions: getHydratedSection(DcwProjectPositions),
    DcwSectionWithHtml: getHydratedSection(DcwSectionWithHtml),
    DcwYourFutureTeam: getHydratedSection(DcwYourFutureTeam),
    DcwOtherProjects: getHydratedSection(DcwOtherProjects),
    DcwTextPageCTA: getHydratedSection(DcwTextPageCTA)
  },
  props: {
    sidebarInitialPosition: {
      type: Number,
      default: 40
    },
    cta: {
      type: Object as PropType<LayoutCta>,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    colors: {
      type: Object as PropType<ProjectColors>,
      required: true
    },
    content: {
      type: Object as PropType<ProjectLayoutContent>,
      required: true
    }
  },
  setup(props) {
    const LAYOUT_CLASS = '.dcw-project-layout';
    const contentRef = ref(null);
    const { updateStyleSheet } = useStyleSheet();

    const ctaHeadlineProps = {
      ...props.cta?.headline,
      styledAs: HeadlineTypes.H3,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const clickHandler = (evt: MouseEvent) => {
      const target = evt.target as HTMLAnchorElement;

      if (!target.hash) {
        return;
      }

      const neededSection = document.querySelector(target.hash);

      if (!neededSection) {
        return;
      }

      evt.preventDefault();
      neededSection.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };

    const colors = getHEXOpacityColor(props.color);

    updateStyleSheet([
      {
        selectors: [ProjectSelectors.HEADER, LAYOUT_CLASS],
        properties: [
          { key: '--dcw-color-primary-ultra-light', value: colors.twenty },
          { key: CssVariables.COLOR_PRIMARY_LIGHT, value: props.colors.light },
          { key: '--dcw-color-primary-lighter', value: colors.thirty },
          { key: CssVariables.COLOR_PRIMARY, value: props.colors.primary },
          { key: CssVariables.COLOR_BACKGROUND, value: props.colors.heroBg },
          { key: CssVariables.COLOR_PROJECT_HERO_LINE, value: props.colors.heroLine || props.colors.light }
        ]
      }
    ]);

    const sidebarData: SidebarData = {
      sections: [
        props.content.description,
        props.content.technologies,
        props.content.howWeWork,
        props.content.challenges,
        props.content.team,
        props.content.openPositions,
        props.content.otherProjects
      ],
      extraSections: [props.content.cta]
    };

    const ctaButtonProps = {
      ...props.cta.button,
      isLink: true,
      icon: false
    };

    return {
      MainGridWrapperElements,
      ProjectSectionsId,
      ctaHeadlineProps,
      ctaButtonProps,
      HeadlineTypes,
      LAYOUT_CLASS,
      clickHandler,
      sidebarData,
      contentRef
    };
  },
  head: {}
});

export default DcwProjectLayout;
