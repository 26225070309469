






































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwScrollSnap, DcwButton, DcwImage, DcwFlag } from '@atoms';
import { DcwPersonData, DcwScheduleModal } from '@molecules';
import { ButtonVariants, GlobalWorkSectionContent, HeadlineSubtitleSizes, HeadlineTypes, GlobalWorkSectionCard } from '@/types';
import { useLocales } from '@/composables';
import { gaHandler } from '@/helpers';

export const DcwGlobalWork = defineComponent({
  name: 'DcwGlobalWork',
  components: { DcwFlag, DcwImage, DcwButton, DcwScrollSnap, DcwPersonData, DcwNewHeadline, DcwGridWrapper, DcwScheduleModal },
  props: {
    content: {
      type: Object as PropType<GlobalWorkSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const activeModal = ref<GlobalWorkSectionCard['modal'] | null>(null);

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const buttonProps = {
      ...props.content.button,
      isLink: !!props.content.button.href
    };

    const modalButtonProps = {
      variant: ButtonVariants.SECONDARY,
      isSmall: true,
      text: translation.buttons.scheduleACall
    };

    const modalClicked = (item: GlobalWorkSectionCard) => {
      gaHandler({ _event: 'call-form', event: 'gtm.linkClick' });
      activeModal.value = item.modal;
    };

    return { modalButtonProps, activeModal, headlineProps, buttonProps, modalClicked };
  }
});

export default DcwGlobalWork;
