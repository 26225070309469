























































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwAccordion, DcwImage, DcwTooltip } from '@atoms';
import { Directions, HWSSectionContent, TooltipColors } from '@/types';

export const DcwHWSTabCommunication = defineComponent({
  name: 'DcwHWSTabCommunication',
  components: { DcwImage, DcwTooltip, DcwAccordion },
  props: {
    content: {
      type: Object as PropType<HWSSectionContent['tabs']['communication']['content']>,
      required: true
    }
  },
  setup() {
    return { Directions, TooltipColors };
  }
});

export default DcwHWSTabCommunication;
