


















import { defineComponent, ref, PropType } from '@nuxtjs/composition-api';
import { DcwJobOfferCta, DcwJobsWrapper } from './components';
import { DcwNewHeadline, DcwGridWrapper, DcwButton } from '@atoms';
import { DcwOfferItem } from '@molecules';
import { useLocales } from '@/composables';
import { ButtonVariants, HeadlineTypes, JobsOffersSectionContent, PagesLinks } from '@/types';

export const DcwJobOffersSection = defineComponent({
  name: 'DcwJobOffersSection',
  components: { DcwNewHeadline, DcwButton, DcwGridWrapper, DcwOfferItem, DcwJobOfferCta, DcwJobsWrapper },
  props: {
    content: {
      type: Object as PropType<JobsOffersSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const isModalShown = ref(false);

    const toAllButton = {
      variant: ButtonVariants.SECONDARY,
      text: translation.buttons.allJobOffers,
      isLink: true,
      href: PagesLinks.JOBS
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2
    };

    return { isModalShown, toAllButton, headlineProps };
  }
});

export default DcwJobOffersSection;
