import { onBeforeUnmount, onMounted, ref, Ref } from '@nuxtjs/composition-api';
import { useBreakpoints } from '@/composables';
import { Breakpoints } from '@/types';

interface UseMobileTimelineProps {
  wrapperRef: Ref<HTMLElement | null>;
  handler: (direction: number) => void;
}

const MOBILE_GAP = 24;

export const useMobileTimeline = (props: UseMobileTimelineProps) => {
  const { currentWidth } = useBreakpoints();
  const prevCurrentWidth = ref<number>(currentWidth.value);
  let timerId: NodeJS.Timeout;

  const calculation = () => {
    const wrapper = props.wrapperRef.value;
    if (!wrapper) {
      return;
    }

    const childrenArray = Array.from(wrapper.children) as HTMLDivElement[];
    const center = Math.round(wrapper.scrollLeft + wrapper.clientWidth / 2);

    const arr = childrenArray.map((slide) => {
      return slide.offsetLeft + slide.clientWidth / 2 - MOBILE_GAP / 2;
    });

    if (wrapper.scrollLeft === 0) {
      return 0;
    }

    return arr.findIndex((item) => item === center);
  };

  const scrollHandler = () => {
    if (currentWidth.value !== prevCurrentWidth.value) {
      prevCurrentWidth.value = currentWidth.value;
      return;
    }

    if (currentWidth.value >= Breakpoints.SMALL) {
      return;
    }

    clearTimeout(timerId);
    timerId = setTimeout(() => {
      const index = calculation();
      if (typeof index === 'number') {
        if (currentWidth.value >= Breakpoints.SMALL) {
          return;
        }
        props.handler(index);
      }
    }, 66);
  };

  onMounted(() => {
    if (!props.wrapperRef.value) {
      return;
    }

    props.wrapperRef.value.addEventListener('scroll', scrollHandler);
  });

  onBeforeUnmount(() => {
    if (!props.wrapperRef.value) {
      return;
    }

    props.wrapperRef.value.removeEventListener('scroll', scrollHandler);
  });
};
