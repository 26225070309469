
































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwContactForm } from './components/DcwContactForm.vue';
import { DcwGridWrapper, DcwNewHeadline, DcwLink, DcwBadge } from '@atoms';
import { DcwPersonCard } from '@molecules';
import { ButtonVariants, ContactSectionContent, HeadlineTypes } from '@/types';
import { useLocales } from '@/composables';

export const DcwContactSection = defineComponent({
  name: 'DcwContactSection',
  components: { DcwLink, DcwBadge, DcwPersonCard, DcwNewHeadline, DcwGridWrapper, DcwContactForm },
  props: {
    content: {
      type: Object as PropType<ContactSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const cards = Object.values(props.content.people.cards).map((item) => ({
      ...item.model,
      name: `${item.model.name} ${item.model.surname}`,
      description: item.text,
      img: item.image,
      button: {
        text: translation.buttons.scheduleACall,
        variant: ButtonVariants.SECONDARY,
        isSmall: true
      }
    }));

    const headlineProps = {
      before: props.content.headline.before,
      highlight: props.content.headline.highlight,
      type: HeadlineTypes.H1,
      styledAs: HeadlineTypes.H3
    };

    const secondHeadlineProps = {
      ...props.content.formHeading,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3
    };

    const peopleHeading = {
      ...props.content.people.heading,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3
    };

    return { cards, headlineProps, peopleHeading, secondHeadlineProps };
  }
});

export default DcwContactSection;
