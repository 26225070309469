

















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwServicesItem } from './components/DcwServicesItem.vue';
import { DcwGridWrapper, DcwNewHeadline } from '@atoms';
import { HeadlineTypes, ServicesSectionContent } from '@/types';

export const DcwServicesSection = defineComponent({
  name: 'DcwServicesSection',
  components: { DcwGridWrapper, DcwServicesItem, DcwNewHeadline },
  props: {
    content: {
      type: Object as PropType<ServicesSectionContent>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2
    };

    return { headlineProps };
  }
});

export default DcwServicesSection;
