





































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwImage, DcwSwiperPagination, DcwSwiper } from '@atoms';
import { CareerPathItem, SliderDirection, SwiperPropsOptions } from '@/types';
import { useSwiper } from '@/composables';

export const DcwCareerPathSlider = defineComponent({
  name: 'DcwCareerPathSlider',
  components: { DcwImage, DcwSwiper, DcwSwiperPagination },
  props: {
    slides: {
      type: Array as PropType<CareerPathItem[]>,
      required: true
    }
  },
  setup(props) {
    const sliderRef = ref(null);
    const { onSlideChange, swiperInfo, nextSlideHandler, prevSlideHandler } = useSwiper({
      length: props.slides.length,
      initialSlide: 2,
      sliderRef
    });

    const sliderOptions: SwiperPropsOptions<CareerPathItem> = {
      swiperConfig: {
        slidesPerView: 1,
        initialSlide: 2,
        speed: 1000,
        breakpoints: {
          360: { slidesPerView: 1.2173 },
          768: { slidesPerView: 1.1 },
          1280: { slidesPerView: 1.2 },
          1568: { slidesPerView: 1.3 }
        }
      },
      slides: props.slides,
      onSlideChange
    };

    return {
      sliderOptions,
      SliderDirection,
      swiperInfo,
      nextSlideHandler,
      prevSlideHandler,
      sliderRef
    };
  }
});

export default DcwCareerPathSlider;
