

















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { MainGridWrapperElements, ReviewsSectionContent } from '@/types';
import { DcwImage, DcwText } from '@atoms';

export const DcwClientTestimonialSlide = defineComponent({
  name: 'DcwClientTestimonialSlide',
  components: { DcwImage, DcwText },
  props: {
    slide: {
      type: Object as PropType<ReviewsSectionContent['slides'][number]>,
      required: true
    }
  },
  setup() {
    return {
      MainGridWrapperElements
    };
  }
});

export default DcwClientTestimonialSlide;
