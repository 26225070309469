




























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwGridWrapper, DcwSwiperPagination, DcwSwiper } from '@atoms';
import { DcwVideoPlayer } from '@organisms';
import { useSwiper } from '@/composables';
import {
  HeadlineSubtitleSpacing,
  HeadlineSubtitleSizes,
  SwiperPropsOptions,
  HeadlineColor,
  HeadlineTypes,
  Breakpoints,
  SwiperRef,
  VideoListingInterviewsContent
} from '@/types';

export const DcwInterviews = defineComponent({
  name: 'DcwInterviews',
  components: { DcwNewHeadline, DcwGridWrapper, DcwSwiperPagination, DcwSwiper, DcwVideoPlayer },
  props: {
    headline: {
      type: Object as PropType<VideoListingInterviewsContent['headline']>,
      required: true
    },
    videos: {
      type: Array as PropType<VideoListingInterviewsContent['videos']>,
      required: true
    }
  },
  setup(props) {
    const mainHeadlineProps = {
      ...props.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const headlineProps = {
      type: HeadlineTypes.H3,
      color: HeadlineColor.LIGHT,
      styledAs: HeadlineTypes.H4,
      subtitleSpacing: HeadlineSubtitleSpacing.SMALL,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const descriptionHeadlineProps = {
      ...headlineProps,
      mobileStyledAs: HeadlineTypes.H6,
      mobileSubtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL
    };

    const sliderRef = ref<SwiperRef | null>(null);

    const { swiperInfo, onSlideChange, nextSlideHandler, prevSlideHandler, setActiveSlide, onProgressChange } = useSwiper({
      length: props.videos.length
    });

    const swiperOptions: SwiperPropsOptions<typeof props.videos[0]> = {
      swiperConfig: {
        speed: 500,
        spaceBetween: 16,
        slidesPerView: 1,
        breakpoints: {
          [Breakpoints.SMALL]: {
            slidesPerView: 'auto',
            spaceBetween: 24
          },
          [Breakpoints.MEDIUM]: {
            slidesPerView: 'auto',
            spaceBetween: 24,
            allowTouchMove: false
          }
        },
        on: {
          progress(_, progress) {
            onProgressChange(progress);
          }
        }
      },
      slides: props.videos,
      onSlideChange
    };

    return {
      sliderRef,
      swiperInfo,
      swiperOptions,
      headlineProps,
      setActiveSlide,
      nextSlideHandler,
      prevSlideHandler,
      mainHeadlineProps,
      descriptionHeadlineProps
    };
  }
});

export default DcwInterviews;
