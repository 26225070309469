var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"timetableSliderRef",staticClass:"dcw-timeline-slider",class:[{ 'dcw-timeline-slider--center-card-wrapper': _vm.calibrateCardToBeInCenter }],style:({ paddingLeft: _vm.paddingValue, paddingRight: _vm.paddingValue })},[_c('client-only',[(_vm.isMobile)?_vm._l((_vm.events),function(event,index){return _c('DcwTimelineMobileWrapper',{key:index,staticClass:"dcw-timeline-slider__event",attrs:{"event":event,"card-variant":_vm.TimetableCardVariant.SINGLE_COLUMN,"observer":{ observe: _vm.observer },"event-dot":{
          index: index,
          closestEventIndex: _vm.closestEventIndex,
          activeEventIndex: _vm.activeCardIndex,
          dateProgress: _vm.dateProgressInPixels
        }}})}):_vm._l((_vm.events),function(event,index){return _c('DcwTimelineDesktopWrapper',{key:("dcw-cluster-card-" + (event.id)),staticClass:"dcw-timeline-slider__event",attrs:{"card-index":index,"event":event,"state":_vm.cardState(index),"event-dot":{
          index: index,
          closestEventIndex: _vm.closestEventIndex,
          activeEventIndex: _vm.activeCardIndex,
          dateProgress: _vm.dateProgressInPixels
        },"observer":{ observe: _vm.observer },"is-last-one":index + 1 === _vm.events.length},on:{"transitionDone":function($event){return _vm.changeCardTransitionState(index)},"more-click":function($event){return _vm.changeSlideHandler(index)}}})})],2),_vm._v(" "),_c('div',{staticClass:"dcw-timeline-slider__line",style:({ width: _vm.timelineWidth + 'px' })},[_c('div',{staticClass:"dcw-timeline-slider__line-indicator",style:({ width: _vm.timelineIndicatorWidth + 'px' })})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }