


























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { Breakpoints, CooperationSectionContent, HeadlineSubtitleSizes, HeadlineTypes, SwiperPropsOptions } from '@/types';
import { DcwGridWrapper, DcwSwiperPagination, DcwSwiper, DcwNewHeadline, DcwText } from '@atoms';
import { useSwiper } from '@/composables';

export const DcwCooperation = defineComponent({
  name: 'DcwCooperation',
  components: { DcwGridWrapper, DcwSwiperPagination, DcwSwiper, DcwNewHeadline, DcwText },
  props: {
    content: {
      type: Object as PropType<CooperationSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { onSlideChange, swiperInfo, nextSlideHandler, prevSlideHandler } = useSwiper({
      length: props.content.items.length,
      isCarousel: true
    });

    const headlineProps = {
      ...props.content.headline,
      isTinted: true,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const sliderOptions: SwiperPropsOptions<CooperationSectionContent['items'][number]> = {
      swiperConfig: {
        slidesPerView: 1.1,
        spaceBetween: 24,
        speed: 1000,
        breakpoints: {
          [Breakpoints.MOBILE]: {
            slidesPerView: 2
          },
          [Breakpoints.MEDIUM_DESKTOP]: {
            slidesPerView: 3
          }
        }
      },
      slides: props.content.items,
      onSlideChange
    };

    return { headlineProps, sliderOptions, swiperInfo, nextSlideHandler, prevSlideHandler };
  }
});

export default DcwCooperation;
