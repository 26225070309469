


































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwLink, DcwSwiper, DcwTechnology, DcwSimpleIcon, DcwNewHeadline, DcwGridWrapper, DcwSwiperPagination } from '@atoms';
import ArrowRight from '@/assets/custom-dc-icons/ArrowRight.vue';
import { useLocales, useSwiper } from '@/composables';
import {
  Breakpoints,
  PagesLinks,
  HeadlineTypes,
  TechnologyWithId,
  SwiperPropsOptions,
  HeadlineSubtitleSizes,
  OthersTechnologiesSectionContent,
  HeadlineSubtitleSpacing
} from '@/types';

export const DcwOthersTechnologies = defineComponent({
  name: 'DcwOthersTechnologies',
  components: { DcwLink, DcwSwiper, DcwTechnology, DcwSimpleIcon, DcwNewHeadline, DcwGridWrapper, DcwSwiperPagination },
  props: {
    content: {
      type: Object as PropType<OthersTechnologiesSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const { onSlideChange, nextSlideHandler, prevSlideHandler, swiperInfo } = useSwiper({
      length: props.content.technologies.length
    });

    const swiperOptions: SwiperPropsOptions<TechnologyWithId> = {
      swiperConfig: {
        slidesPerView: 1,
        spaceBetween: 32,
        speed: 300,
        loop: true,
        breakpoints: {
          [Breakpoints.SMALL_MOBILE]: {
            slidesPerView: 1.1
          },
          991: {
            slidesPerView: 2,
            allowTouchMove: false,
            loopedSlides: 5
          }
        }
      },
      onSlideChange,
      slides: props.content.technologies
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const itemHeadlineProps = {
      isTinted: true,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      mobileSubtitleSize: HeadlineSubtitleSizes.NO
    };

    return {
      swiperInfo,
      PagesLinks,
      ArrowRight,
      translation,
      headlineProps,
      swiperOptions,
      nextSlideHandler,
      prevSlideHandler,
      itemHeadlineProps
    };
  }
});

export default DcwOthersTechnologies;
