






















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwImage, DcwNewHeadline, DcwGridWrapper, DcwText } from '@atoms';
import {
  PagesLinks,
  HeadlineTypes,
  HeadlineColor,
  ButtonVariants,
  StoryBannerContent,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing,
  MainGridWrapperElements
} from '@/types';
import { useBreakpoints, useLocales } from '@/composables';

export const DcwStoryBanner = defineComponent({
  name: 'DcwStoryBanner',
  components: { DcwButton, DcwImage, DcwNewHeadline, DcwGridWrapper, DcwText },
  props: {
    content: {
      type: Object as PropType<StoryBannerContent>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const { isMobile } = useBreakpoints();

    const backButtonProps = {
      isLink: true,
      isLeft: true,
      href: PagesLinks.SUCCESS_STORIES,
      variant: ButtonVariants.TERTIARY,
      text: translation.backButtons.successStories
    };

    const mainHeadlineProps = {
      before: props.content.name,
      subtitle: props.content.position,
      type: HeadlineTypes.P,
      styledAs: HeadlineTypes.H2,
      color: HeadlineColor.LIGHT,
      subtitleSize: HeadlineSubtitleSizes.LARGE,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    return {
      isMobile,
      translation,
      HeadlineTypes,
      backButtonProps,
      mainHeadlineProps,
      MainGridWrapperElements
    };
  }
});

export default DcwStoryBanner;
