

















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import * as SwiperClass from 'swiper';
import { DcwProjectSlide } from './DcwProjectSlide.vue';
import { Breakpoints, Project, SwiperPropsOptions } from '@/types';
import { UseSwiper } from '@/composables';
import { getHEXOpacityColor } from '@/helpers';
import { DcwSwiper } from '@atoms';

export const DcwProjectSlider = defineComponent({
  name: 'DcwProjectSlider',
  components: { DcwProjectSlide, DcwSwiper },
  props: {
    slides: {
      type: Array as PropType<Project[]>,
      required: true
    },
    swiperInfo: {
      type: Object as PropType<UseSwiper['swiperInfo']>,
      required: true
    }
  },
  emits: ['slide-change'],
  setup(props, context) {
    const onSlideChange = (instance: SwiperClass.Swiper) => {
      context.emit('slide-change', instance);
    };

    const swiperOptions: SwiperPropsOptions<Project> = {
      swiperConfig: {
        speed: 300,
        loop: true,
        spaceBetween: 24,
        allowTouchMove: false,
        breakpoints: {
          [Breakpoints.EXTRA_SMALL]: {
            slidesPerView: 1
          },
          1024: {
            slidesPerView: 2
          }
        }
      },
      slides: props.slides,
      onSlideChange
    };

    const borderColor = computed(() => getHEXOpacityColor(props.slides[props.swiperInfo.activeIndex]?.color).thirty);

    return { swiperOptions, borderColor };
  }
});

export default DcwProjectSlider;
