
























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwAccordion, DcwNewHeadline } from '@atoms';
import { HeadlineTypes, PositionFAQSection } from '@/types';

export const DcwFAQ = defineComponent({
  name: 'DcwFAQ',
  components: { DcwNewHeadline, DcwAccordion },
  props: {
    content: {
      type: Object as PropType<PositionFAQSection>,
      required: true
    }
  },
  setup() {
    const activeQuestion = ref<number | null>(null);

    const openHandler = (index: number) => {
      activeQuestion.value = index;
    };

    return { HeadlineTypes, activeQuestion, openHandler };
  }
});

export default DcwFAQ;
