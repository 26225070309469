


















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwText } from '@atoms';
import { DcInNumbersSectionContent } from '@/types';

export const DcwDcInNumbers = defineComponent({
  name: 'DcwDcInNumbers',
  components: { DcwGridWrapper, DcwText },
  props: {
    content: {
      type: Object as PropType<DcInNumbersSectionContent>,
      required: true
    }
  }
});

export default DcwDcInNumbers;
