




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwButton } from '@atoms';
import { ButtonVariants, HeadlineTypes, PagesLinks, RelatedPost } from '@/types';
import { useLocales } from '@/composables';
import { formatDateString } from '@/helpers';

export const DcwRelatedPosts = defineComponent({
  name: 'DcwRelatedPosts',
  components: { DcwNewHeadline, DcwButton },
  props: {
    posts: {
      type: Array as PropType<RelatedPost[]>,
      required: true
    }
  },
  setup() {
    const { translation } = useLocales();

    const headlineProps = {
      before: 'Related Posts',
      type: HeadlineTypes.P,
      styledAs: HeadlineTypes.H5
    };

    const postHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.P
    };

    const buttonProps = {
      variant: ButtonVariants.TERTIARY,
      text: translation.buttons.readMore,
      isLink: true
    };

    return { headlineProps, postHeadlineProps, formatDateString, buttonProps, PagesLinks };
  }
});

export default DcwRelatedPosts;
